import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Flex,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import { blogValidationSchema } from "../../../utils/validation";
import { FiCheck, FiEye, FiFileText } from "react-icons/fi";
import { EditBlogForm } from "../../../models/blog";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  createOrUpdateBlog,
  getBlogDetail,
} from "../../../services/blogService";
import MyUploadAdapter from "../../../services/myUploadAdapter";
import BlogCard from "../Card";
import { motion } from "framer-motion";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const EditBlog: React.FC = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [editorData, setEditorData] = useState("");
  const [initValues, setInitValues] = useState<EditBlogForm>({
    title: "",
    content: "",
  });
  const [previewData, setPreviewData] = useState<EditBlogForm>();
  const [uploadUrls, setUploadUrls] = useState<string[]>([]);
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    { label: "menu.blogs", path: "/workbench/blogs" },
    { label: initValues.title, path: initValues.title },
  ];
  const {
    isOpen: isPreviewOpen,
    onOpen: onPreviewOpen,
    onClose: onPreviewClose,
  } = useDisclosure();
  const MotionBox = motion(Box);

  const Editor: any = ClassicEditor;
  const params = useParams();
  const id = params.id;

  useEffect(() => {
    if (!!id && id !== "new") {
      getBlogDetail(id).then((res) => {
        setInitValues(res);
        setEditorData(res.content);
      });
    }
  }, []);

  const editorConfiguration = {
    extraPlugins: [MyCustomUploadAdapterPlugin],
    allowedContent: true,
    mediaEmbed: {
      previewsInData: true,
      providers: [
        {
          name: "youku-bilibili",
          url: [
            /youku\.com/, // 匹配优酷域名
            /bilibili\.com/, // 匹配B站域名
          ],
          html: (match: { [x: string]: any }) => {
            const iframeTag = match["input"];

            // 正则表达式提取 src 属性的值
            const srcMatch = iframeTag.match(/src=['"]([^'"]+)['"]/);

            // 如果找到了 src 属性
            if (srcMatch && srcMatch[1]) {
              const src = srcMatch[1]; // 提取到的 src 值

              // 设置默认宽度和高度
              const width = 840;
              const height = 473;
              // 判断是优酷还是B站，根据不同平台进行处理
              if (src.includes("youku.com")) {
                // 处理优酷视频嵌入
                return `<div class="responsive-video"><iframe width="${width}" height="${height}" src="${src}" frameborder="0" allowfullscreen></iframe></div>`;
              } else if (src.includes("bilibili.com")) {
                // 处理B站视频嵌入
                return `<div class="responsive-video"><iframe width="${width}" height="${height}" src="${src}" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe></div>`;
              }
            }

            return iframeTag;
          },
        },
        {
          name: "youtube", // 添加对YouTube的支持
          url: [
            /youtube\.com\/watch\?v=([\w\-]+)/, // 标准YouTube URL
            /youtu\.be\/([\w\-]+)/, // YouTube短链接
          ],
          html: (match: { [x: string]: any }) => {
            const videoId = match[1]; // 提取YouTube视频ID
            const width = 840;
            const height = 473;
            return `<div class="responsive-video"><iframe width="${width}" height="${height}" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
          },
        },
        {
          name: "default", // 默认处理程序，不生成 iframe
          url: /^.+/, // 匹配所有 URL
          html: (match: { [x: string]: any }) => {
            const url = match["input"];
            const width = 560;
            const height = 315;
            // 直接返回 URL 或其他自定义处理
            return `<div class="responsive-video"><iframe width="${width}" height="${height}" src="${url}" frameborder="0" allowfullscreen></iframe></div>`;
            // return url;
          },
        },
      ],
    },
  };

  function MyCustomUploadAdapterPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return new MyUploadAdapter(loader, (url: string) => {
        setUploadUrls((prevUrls) => [...prevUrls, url]);
      });
    };
  }

  const onReady = (editor: any) => {
    // 编辑器准备就绪后的回调
    console.log("Editor is ready to use!", editor);
  };

  const onEditorChange = (event: any, editor: any) => {
    // 编辑器内容发生变化的回调
    console.log(editor.getData());
    const data = editor.getData();
    setEditorData(data);
  };

  const onError = (error: any) => {
    // 编辑器发生错误的回调
    console.error("Something went wrong:", error);
  };

  //需要盘点个这个id属于这个人 后端
  const handleCreateBlog = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let editData: any = {
      title: values.title,
      content: values.content,
      tags: values.tags,
      isDraft: values.isDraft,
    };
    let removedUrls: string[] = [];
    uploadUrls.forEach((url) => {
      if (!editData.content.includes(url)) {
        removedUrls = [...removedUrls, url];
      }
    });
    if (removedUrls.length > 0) {
      editData.deletedFileUrls = removedUrls;
    }
    if (!!values?.isPreview) {
      setPreviewData(editData);
      onPreviewOpen();
    } else {
      let opt = "create";
      if (!isEmpty(values.id)) {
        editData.id = values.id;
        opt = "update";
      }
      createOrUpdateBlog(editData)
        .then((res) => {
          toast({
            title: t("common.btn." + opt + "-success-msg"),
            position: "top",
            status: "success",
            isClosable: true,
            duration: 3000,
          });
          setUploadUrls([]);
          formikHelpers.setSubmitting(false);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: t("common.btn." + opt + "-error-msg"),
            position: "top",
            status: "error",
            isClosable: true,
            duration: 3000,
          });
          formikHelpers.setSubmitting(false);
        });
    }
  };

  return (
    <Box>
      {!isPreviewOpen && (
        <Box maxW="1240px" margin="0 auto" minH="30rem" px="1rem">
          <Box py="1rem">
            <CustomBreadcrumb routes={breadcrumbRoutes} />
          </Box>

          <Box bg={"#fff"} p="1rem">
            <Formik
              enableReinitialize
              initialValues={initValues}
              validationSchema={blogValidationSchema}
              onSubmit={handleCreateBlog}
            >
              {(props) => (
                <Form>
                  <Flex justifyContent="end">
                    <Button
                      leftIcon={<FiEye />}
                      size="sm"
                      isDisabled={isEmpty(editorData)}
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("content", editorData);
                        props.setFieldValue("isPreview", true);
                        props.submitForm();
                      }}
                    >
                      preview
                    </Button>
                  </Flex>
                  <FormControl mb={4}>
                    <Box my="0.5rem">
                      <Field name="title">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.title && form.touched.title}
                            isRequired
                          >
                            <FormLabel>{t("blogs.title")}:</FormLabel>
                            <Input {...field} />
                            <FormErrorMessage>
                              {form.errors.title}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                  </FormControl>
                  <FormControl mb={4}>
                    <Field name="tags">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.tags && form.touched.tags}
                        >
                          <FormLabel>{t("blogs.tags")}:</FormLabel>
                          <Input 
                            {...field} 
                            placeholder={t("blogs.enterTags")} 
                          />
                          <FormErrorMessage>
                            {form.errors.tags}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </FormControl>
                  <FormControl mb={4}>
                    <Field name="content">
                      {({ form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.content && form.touched.content
                          }
                        >
                          <FormLabel>{t("blogs.content")} :</FormLabel>
                          <Box
                            maxH={{ base: "20rem", md: "25rem" }}
                            overflow="auto"
                            className="blog-editor"
                          >
                            <CKEditor
                              editor={Editor}
                              data={editorData}
                              onReady={onReady}
                              onChange={onEditorChange}
                              onError={onError}
                              config={editorConfiguration}
                            />
                          </Box>
                        </FormControl>
                      )}
                    </Field>
                  </FormControl>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ md: "flex-end" }}
                  >
                    <Button
                      leftIcon={<FiFileText />}
                      minW="8rem"
                      mr="1rem"
                      colorScheme="gray"
                      isDisabled={isEmpty(editorData)}
                      isLoading={props.isSubmitting}
                      onClick={() => {
                        props.setFieldValue("content", editorData);
                        props.setFieldValue("isDraft", true, false);
                        props.submitForm();
                      }}
                    >
                      {t("common.btn.save-as-draft")}
                    </Button>
                    <Button
                      leftIcon={<FiCheck />}
                      minW="8rem"
                      colorScheme="teal"
                      isDisabled={isEmpty(editorData)}
                      isLoading={props.isSubmitting}
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("content", editorData);
                        props.setFieldValue("isDraft", false, false);
                        props.submitForm();
                      }}
                    >
                      {t("common.btn.publish")}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      )}
      {isPreviewOpen && (
        <MotionBox
          initial={{ x: isPreviewOpen ? "100%" : 0 }}
          animate={{ x: isPreviewOpen ? 0 : "100%" }}
          transition={{ duration: 0.5 }}
          width="100%"
        >
          <BlogCard
            isOpen={isPreviewOpen}
            onClose={onPreviewClose}
            initValues={previewData!}
            isPreview={true}
          ></BlogCard>
        </MotionBox>
      )}
    </Box>
  );
};

export default EditBlog;
