import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Editable,
  EditablePreview,
  EditableInput,
  Flex,
  Card,
  CardBody,
  Text,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  Textarea,
  Stack
} from "@chakra-ui/react";
import { Formik, Form, Field, useField } from "formik";
import _, { cloneDeep, isArray, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { EditDispatchTaskForm, TasksGroup } from "../../../../models/dispatchTask";
import { createOrUpdateDispatchTask, getDispatchTasksGroups } from "../../../../services/dispatchTasksService";
import { Select as ChakraSelect } from "chakra-react-select";
import { Options } from "../../../../models/common";
import { JSONEditor as CustomEditor } from "../../../../components/JSONEditor";
import "react-datetime/css/react-datetime.css";
import { InvoiceInfoItem } from "../../../../models/invoice";
import { getInvoiceInfoList } from "../../../../services/invoiceService";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
import InvoiceInfoTable from "../InvoiceInfoTable";
import invoiceExtra from "../../../../assets/data/invoice.json";
import { isNumeric, transferFromInvoiceUserParameters } from "../../../../utils/invoice";
import PreviewInvoice from "../Preview";
import ImportInvoiceList from "./Import";
import { NODE_TYPE } from "../../../../constants/constants";

interface AddFormProps {
  isOpen: boolean;
  initialValues: EditDispatchTaskForm;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

interface GroupOptions extends Options {
  application?: number;
  groupDescription?: string;
}

interface ExtraInvoice {
  name: string;
  code: string;
  required?: boolean;
  value_type?: string;
  default_value?: string;
  placeholder?: string;
  values?: {
    label: string;
    value: string | number;
  }[]
}

const CreateDispatchTask: React.FC<AddFormProps> = ({
  isOpen,
  initialValues,
  onOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [groupList, setGroupList] = useState<TasksGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupOptions | null>();
  const [selectedSeller, setSelectedSeller] = useState<InvoiceInfoItem | null>();
  const [selectedBuyer, setSelectedBuyer] = useState<InvoiceInfoItem | null>();
  const [invoiceInfos, setInvoiceInfos] = useState<InvoiceInfoItem[]>();
  const [invoiceListFields, setInvoiceListFields] = useState<string[]>([]);
  const [invoiceList, setInvoiceList] = useState<any[]>([]);
  const [executeType, setExecuteType] = useState<any>();
  const [userParametersTreeData, setUserParametersTreeData] = useState<{
    userParametersTree: any
    userParametersSource: any
  }>();
  const [invoiceInfoTitle, setInvoiceInfoTitle] = useState("");

  const taskType = [
    { value: "0", label: t("dispatch-task.types.0") },
    { value: "1", label: t("dispatch-task.types.1") },
  ];
  const {
    isOpen: isPreviewInvoice,
    onOpen: onPreviewInvoiceOpen,
    onClose: onPreviewInvoiceClose,
  } = useDisclosure();

  const {
    isOpen: isImportModal,
    onOpen: onImportModalOpen,
    onClose: onImportModalClose,
  } = useDisclosure();

  useEffect(()=>{
    setSelectedBuyer(undefined);
    setSelectedSeller(undefined);
    fetchGroupList();
    fetchInvoiceInfoList();
    if(!initialValues?.taskName) {
      setExecuteType('0');
      setSelectedGroup(null);
      setUserParametersTreeData({userParametersTree: null, userParametersSource: null});
    } else {
      setExecuteType(initialValues.executeType?.toString());
    }



  }, [isOpen]);


  

  useEffect(()=> {
    console.log(123);
    updateInvoiceListInfo();
  }, [userParametersTreeData])

  const fetchInvoiceInfoList = () => {
      getInvoiceInfoList().then((res) => {
        const data = res.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.name,
          }
        })
        setInvoiceInfos(_.orderBy(data, "createdDate", "desc"));
      });
    };

  const updateInvoiceListInfo = () => {
    if(selectedGroup?.application === 0) {
      const source = userParametersTreeData?.userParametersSource?.invoice_details;
      const value = userParametersTreeData?.userParametersTree?.invoice_details;
  
      if(source?.length) {
        setInvoiceListFields(_.values(source[0]));
        if(!initialValues.taskName && value.length) {
          setInvoiceList([value[0]]);
        } else if(!invoiceList.length && value) {
          setInvoiceList(value);
        }
      } 
    } else {
      const source = userParametersTreeData?.userParametersSource?.invoice_details;
      const value = userParametersTreeData?.userParametersTree?.invoice_details;
  
      if(source?.length) {
        setInvoiceListFields(_.values(source[0]));
        setInvoiceList(value);
      } 
    }

  }

  const fetchGroupList = () => {
    getDispatchTasksGroups().then((res) => {
      setInvoiceList([]);
      setGroupList(res);
      if(initialValues?.taskName) {
        const item = groupList.find(item => item.id === initialValues.groupId);
        const selected = item ? {
          label: item.groupName,
          value: item.id,
          application: item.application,
          groupDescription: item.groupDescription
        } as GroupOptions: null;
        setSelectedGroup(selected);
        const parameters = groupList.find(group => group.id === selected?.value);
        const jsonObject = JSON.parse(parameters?.userParameters || '');         
          try {
            const json = {
              userParametersTree: parseParametersToJsonTree(jsonObject),
              userParametersSource: parseParametersToTranslateTree(jsonObject)
            };
            if(initialValues.userParameters) {
              // 开票
              if(selected?.application === 0) {
                const {sellerObject, buyerObject, infoObject } = transferFromInvoiceUserParameters(initialValues.userParameters);
                const seller = invoiceInfos?.find(item => item.name === sellerObject?.seller_name);
                const buyer = invoiceInfos?.find(item => item.name === buyerObject?.buyer_name);
                if(seller) {
                 setSelectedSeller(seller);
                }
                if(buyer) {
                  setSelectedBuyer(buyer);
                 }
                setUserParametersTreeData({
                  userParametersSource: json.userParametersSource,
                  userParametersTree: infoObject
                });
              } else {
                const existingValues = JSON.parse(initialValues.userParameters);
                // Handle the select field specially
                const mergedTree = mergeOptionsWithExistingValues(json.userParametersTree, existingValues);

                setUserParametersTreeData({
                  userParametersSource: json.userParametersSource,
                  userParametersTree: mergedTree
                });
              }              
            } else {
              setUserParametersTreeData(json);
            }
          } catch{
            setUserParametersTreeData({
              userParametersTree: null,
              userParametersSource: null
            });
          }
        }
    });
  };

  const mergeOptionsWithExistingValues = (template: any, existingValues: any) => {
    // Create a deep copy to avoid modifying the original
    const result = _.cloneDeep(template);
    
    // Function to recursively check for fields with options
    const processNode = (templateNode: any, existingNode: any, path: (string | number)[] = []) => {
      if (!templateNode || !existingNode) return;
      
      // Process each key in the template
      Object.keys(templateNode).forEach(key => {
        const currentPath = [...path, key] as (string | number)[];
        
        // If this is an object with options, make sure to preserve the options
        if (templateNode[key] && typeof templateNode[key] === 'object' && templateNode[key].options) {
          // Keep the options from template but use the value from existing data
          templateNode[key].value = existingNode[key] || templateNode[key].value;
        } 
        // If it's a nested object, process it recursively
        else if (templateNode[key] && typeof templateNode[key] === 'object' && !Array.isArray(templateNode[key])) {
          processNode(templateNode[key], existingNode[key] || {}, currentPath);
        } 
        // If it's an array, process each item
        else if (Array.isArray(templateNode[key])) {
          if (Array.isArray(existingNode[key]) && existingNode[key].length) {
            templateNode[key].forEach((item: any, idx: number) => {
              if (existingNode[key][idx]) {
                processNode(item, existingNode[key][idx], [...currentPath, idx]);
              }
            });
          }
        } 
        // For simple fields, just use the existing value
        else if (existingNode[key] !== undefined) {
          templateNode[key] = existingNode[key];
        }
      });
      
      return templateNode;
    };
    
    return processNode(result, existingValues) || result;
  };

  const cleanupOptionsForSave = (data: any): any => {
    // For null or undefined values
    if (data === null || data === undefined) {
      return data;
    }
    
    // For arrays, process each element
    if (Array.isArray(data)) {
      return data.map(item => cleanupOptionsForSave(item));
    }
    
    // For objects
    if (typeof data === 'object') {
      // Create a new object to hold the cleaned data
      const cleanedData: Record<string, any> = {};
      
      // Process each key in the object
      Object.keys(data).forEach(key => {
        // If it's an object with options property, extract just the value
        if (data[key] && typeof data[key] === 'object' && data[key].options) {
          cleanedData[key] = data[key].value;
        } 
        // Otherwise recursively clean the value
        else {
          cleanedData[key] = cleanupOptionsForSave(data[key]);
        }
      });
      
      return cleanedData;
    }
    
    // For primitives, return as is
    return data;
  };

  const transferInvoiceUserParameters = (seller: InvoiceInfoItem, buyer: InvoiceInfoItem, infoList: any[], values: any)=> {
    const infoObject = {
      "invoice_details": infoList
    };
    const buyerObject = {
      "buyer_name": buyer.name || '',
      "buyer_taxpayer_id": buyer.usci || '',
      "buyer_address": buyer.address || '',
      "buyer_phone": buyer.phone || '',
      "display_buyer_contact_info": buyer.displayAddress || false,
      "buyer_bank_name": buyer.bankName || '',
      "buyer_bank_account": buyer.bankAccount || '',
      "display_buyer_bank_info": buyer.displayBankAccount || false
    };
    const buyerExtraObject = {}, sellerExtraObject = {};
    invoiceExtra.sellerExtra.map(item => {
      sellerExtraObject[item.code] = values[item.code] || '';
      return item;
    });
    invoiceExtra.buyerExtra.map(item => {
      buyerExtraObject[item.code] = values[item.code] || '';
      return item;
    });

    const buyerInfoObject = [Object.assign({}, infoObject, buyerObject, buyerExtraObject)];

    const userParameters = [Object.assign({}, {
      "seller_name" : seller.name || '',
      "seller_taxpayer_id": seller.usci || '',
      "seller_address": seller.address,
      "seller_phone": seller.phone,
      "seller_bank_name": seller.bankName,
      "seller_bank_account": seller.bankAccount,
      "buyer_info": buyerInfoObject
    }, sellerExtraObject)];

    return userParameters;
  }

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    if(selectedGroup?.application === 0) {
      const invoiceParameters = transferInvoiceUserParameters(selectedSeller!, selectedBuyer!, invoiceList, values);
      values = {
        ...values,
        userParameters: JSON.stringify(invoiceParameters)
      }
    } else {
      if(userParametersTreeData?.userParametersTree) {

        const cleanedData = cleanupOptionsForSave(userParametersTreeData?.userParametersTree);

        values = {
          ...values,
          userParameters: JSON.stringify(cleanedData)
        }
      } else {
        values = {
          ...values,
          userParameters: ''
        }
      }
    }
    
    createOrUpdateDispatchTask(values)
      .then((res) => {
        handleClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  const mapJsonItem = (data: any[] | any) => {
    let obj = {};
    data.map(item => {
      if(item.type === NODE_TYPE.LEAF) {
        if(item.value_type === 'boolean') {
          obj[item.code] = false;
        } else if(item.options) {
          // Handle fields with options like select
          // e.g.
          // reason: {
          //   value: 'others',
          //   options: {
          //     type: 'single',
          //     default_value: 'others',
          //     values: [
          //       { "label": "个人原因", "value": "personal_reason" },
          //       { "label": "公司原因", "value": "company_reason" },
          //       { "label": "其他", "value": "others" }
          //     ]
          //   }
          // }
          obj[item.code] = {
            value: item.options.default_value || "",
            options: item.options
          };
        } else {
          obj[item.code] = "";
        }
      } else {
        obj[item.code] = mapJsonItem(item.childs) ;
      }
      return item;
    });
    return [obj, initialValues];
  }

  const parseParametersToJsonTree = (data: {code: "", name: "", value_type: "string", childs: []}) => {
    if(!_.isObject(data)) {
      return null;
    }
    setInvoiceInfoTitle(data.name);
    if(isArray(data)) {
      return  mapJsonItem(data);
    }
    const obj = {};
    obj[data.code] = mapJsonItem(data.childs);
    return obj;
  }

  const mapTranslateItem = (data: any[]) => {
    let obj = {};
    data.map(item => {
      if(item.type === 0) {
        obj[item.code] = item.name;
      } else {
        obj[item.code] = mapTranslateItem(item.childs);
        obj[item.code + '_label'] = item.name;
      }
      return item;
    });
    return [obj];
  }

  const parseParametersToTranslateTree = (data: {code: "", name: "", value_type: "string", childs: []}) => {
    if(!_.isObject(data)) {
      return null;
    }
    if(isArray(data)) {
      return mapTranslateItem(data)
    }
    const obj = {};
    obj[data.code] = mapTranslateItem(data.childs);
    obj[data.code+'_label'] = data.name;
    return obj;
  }

  // 单独组件
  const BooleanCheckbox = ({ row, index }) => {
    const [field, meta, helpers] = useField(row.code);
    
    // 设置初始值
    React.useEffect(() => {
      if (field.value === undefined && row.default_value !== undefined) {
        const boolValue = row.default_value === 'true'
        helpers.setValue(boolValue);
      }
    }, [row.default_value]);

    return (
      <Box my="0.5rem">
        <FormControl display="flex" isRequired={row.required}>
          <Checkbox
            {...field}
            colorScheme="cyan"
            isChecked={Boolean(field.value)}
            onChange={(e) => {
              helpers.setValue(e.target.checked);
              helpers.setTouched(true);
            }}
          >
            {row.name}
          </Checkbox>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      </Box>
    );
  };


  const MultiCheckboxField = ({ row }) => {
    const [field, meta, helpers] = useField(row.code);
    
    // 处理默认值
    React.useEffect(() => {
      if (field.value === undefined || field.value === null) {
        const initialValue = Array.isArray(row.default_value) 
          ? row.default_value 
          : row.default_value 
            ? [row.default_value] 
            : [];
        helpers.setValue(initialValue.join(';'));
      }
    }, [row.default_value]);
  
    // 获取当前值数组
    const currentValues = typeof field.value === 'string' && field.value 
      ? field.value.split(';') 
      : [];
  
    return (
      <Box my="0.5rem">
        <FormControl
        
          isRequired={row.required}
        >
          <FormLabel>{row.name}:</FormLabel>
          <Box overflowX="auto" py={2}>
            <Flex 
              display="inline-flex" 
              minWidth="min-content"
              gap={4}
            >
              {row.values?.map((option) => (
                <Checkbox
                  key={option.value}
                  colorScheme="cyan"
                  value={option.value}
                  isChecked={currentValues.includes(option.value)}
                  onChange={(e) => {
                    const newArray = e.target.checked
                      ? [...currentValues, option.value]
                      : currentValues.filter((v: string) => v !== option.value);
                    
                    helpers.setValue(newArray.join(';'));
                    helpers.setTouched(true);
                  }}
                  onBlur={() => helpers.setTouched(true)}
                >
                  {option.label}
                </Checkbox>
              ))}
            </Flex>
          </Box>
          <FormErrorMessage>
            {meta.error}
          </FormErrorMessage>
        </FormControl>
      </Box>
    );
  };

  const FormikSelect = ({ row }) => {
    const [field, meta, helpers] = useField(row.code);
    
    // 设置初始值
    React.useEffect(() => {
      if (field.value === undefined && row.default_value !== undefined) {
        helpers.setValue(row.default_value);
      }
    }, [row.default_value]);
  
    return (
      <Box my="0.5rem">
        <FormControl
          isRequired={row.required}
        >
          <FormLabel>{row.name}:</FormLabel>
          <ChakraSelect
            colorScheme="purple"
            options={row.values || []}
            value={row.values?.find(item => item.value === field.value)}
            onChange={(selectedOption) => {
              helpers.setValue(selectedOption?.value || '');
              helpers.setTouched(true);
            }}
            onBlur={() => helpers.setTouched(true)}
          />
          <FormErrorMessage>
            {meta.error}
          </FormErrorMessage>
        </FormControl>
      </Box>
    );
  }; 
  
  const formatInvoiceNumber = (value: number) => {
    return value.toFixed(11);
  }
   const _renderTableRow = (row: any, index: number) => {
      return (       
       <>
        <Tr key={index}>
          {_.keys(row).map(key => {
            
            if(key === 'item_total_price') {
              return <Td><Editable defaultValue={row[key] || '-'} value={row[key] || '-'}>
                        <EditablePreview />
                        <EditableInput 
                          onChange={e =>{
                            const newArr = cloneDeep(invoiceList);
                            newArr[index][key] = e.target.value;
                            if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_price_with_tax'])) {
                              const quantity = parseFloat(e.target.value) / parseFloat(newArr[index]['item_price_with_tax']);
                              newArr[index]['item_quantity'] = formatInvoiceNumber(quantity);
                            } else if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_quantity'])) {
                              const price = parseFloat(e.target.value) / parseFloat(newArr[index]['item_quantity']);
                              newArr[index]['item_price_with_tax'] = formatInvoiceNumber(price);
                            }
                            setInvoiceList(newArr);
                            }}/>
                      </Editable></Td>
            } else if(key === 'item_price_with_tax') {
              return <Td><Editable defaultValue={row[key] || '-'} value={row[key] || '-'}>
                      <EditablePreview />
                      <EditableInput 
                        onChange={e =>{
                          const newArr = cloneDeep(invoiceList);
                          newArr[index][key] = e.target.value;
                          if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_total_price'])) {
                            const quantity = parseFloat(newArr[index]['item_total_price']) / parseFloat(e.target.value);
                            newArr[index]['item_quantity'] = formatInvoiceNumber(quantity);
                          } else if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_quantity'])) {
                            const total = parseFloat(e.target.value) * parseFloat(newArr[index]['item_quantity']);
                            newArr[index]['item_total_price'] = total;
                          }
                          setInvoiceList(newArr);
                          }}/>
                    </Editable></Td>
            } else if(key === 'item_quantity') {
              return <Td><Editable defaultValue={row[key] || '-'} value={row[key] || '-'}>
                      <EditablePreview />
                      <EditableInput 
                        onChange={e =>{
                          const newArr = cloneDeep(invoiceList);
                          newArr[index][key] = e.target.value;
                          if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_total_price'])) {
                            const price = parseFloat(newArr[index]['item_total_price']) / parseFloat(e.target.value);
                            newArr[index]['item_price_with_tax'] = formatInvoiceNumber(price);
                          } else if(isNumeric(e.target.value) && isNumeric(newArr[index]['item_price_with_tax'])) {
                            const total = parseFloat(e.target.value) * parseFloat(newArr[index]['item_quantity']);
                            newArr[index]['item_total_price'] = total;
                          }
                          setInvoiceList(newArr);
                          }}/>
                    </Editable></Td>
            }
            return <Td><Editable defaultValue={row[key] || '-'} value={row[key] || '-'}>
            <EditablePreview />
            <EditableInput 
              onChange={e =>{
                const newArr = cloneDeep(invoiceList);
                newArr[index][key] = e.target.value;
                setInvoiceList(newArr);
                }}/>
          </Editable></Td>
          })}
          <Td>
            <Flex>
              { index === invoiceList.length -1 &&
              <FaPlusSquare
              color="teal" 
              onClick={()=>{
                const value = cloneDeep(invoiceList[invoiceList.length -1]);
                setInvoiceList([...invoiceList, value]);
              }}
              ></FaPlusSquare>}
              { invoiceList.length !== 1 &&
                <FaMinusSquare
                color="red" 
                onClick={()=>{
                  const newArr = invoiceList;
                  newArr.splice(index, 1);
                  setInvoiceList([...newArr]);
                }}
                ></FaMinusSquare>
              }
            </Flex>          
          </Td>          
        </Tr>
        </>
      );
    };

  const _renderFormItem = (row: ExtraInvoice, index) => {
    if (row.value_type === 'boolean') {
      return (
        <BooleanCheckbox row={row} index={index} />
        
      );
    }else if (row.value_type === 'multi-checkbox') {
      return (
        <MultiCheckboxField row={row} />
      
      );
    }
     else if(row.value_type === 'select') {
        return <FormikSelect row={row} />;
    } else if (row.value_type === 'textarea') {
      return (
        <Box my="0.5rem">
          <Field name={row.code} key={index}>
            {({ field, form }: any) => (
              <FormControl
                isInvalid={form.errors[row.code] && form.touched[row.code]}
                isRequired={row.required}
              >
                <FormLabel>{row.name}:</FormLabel>
                <Textarea
                  {...field}
                  rows={4}
                  placeholder={row.placeholder || ''}
                  resize="vertical"
                />
                <FormErrorMessage>
                  {form.errors[row.code]}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Box>
      )
    } else {
      return  <Box my="0.5rem"><Field name={row.code} key={index}>
      {({ field, form }: any) => (
        <FormControl
          isInvalid={
            form.errors[row.code] && form.errors[row.code]
          }
          isRequired={row.required}
        >
          <FormLabel>
            {row.name}:
          </FormLabel>
          <Input {...field} />
          <FormErrorMessage>
            {form.errors[row.code]}
          </FormErrorMessage>
        </FormControl>
      )}
    </Field></Box>
    }
  };

  const _renderFormWithAccordion = (data: any[]) => {
    const visibleItems = data.filter(item => !item.defaultHide);
    const hiddenItems = data.filter(item => !!item.defaultHide);

    return <>{visibleItems.map((row: any, idx) => {
              return _renderFormItem(row, idx);
            })}
            {hiddenItems.length > 0 &&
            <Accordion allowToggle>
            <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {t('common.btn.show-more')}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            {hiddenItems.map((row: any, idx) => {
                  return _renderFormItem(row, idx);
            })}
            </AccordionPanel>
            </AccordionItem>
            </Accordion>
            }</>
  }

  const handleClose = () => {
    setSelectedBuyer(undefined);
    setSelectedSeller(undefined);
    setInvoiceList([]);
    onClose();
  }

  return (
    <>
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "70rem" }}>
        <ModalHeader>
          {t("dispatch-task.name")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreate}
        >
          {(props) => (
            <>
            <Form>
              <ModalBody>
                 <Box my="0.5rem">
                  <Field name="taskName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.taskName && form.touched.taskName
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("dispatch-task.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.taskName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="groupId">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.groupId && form.touched.groupId
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("digital-workforce.workbench.list.group")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          value={selectedGroup}
                          options={groupList!.map((option) => ({
                            label: option.groupName, // 显示的名称
                            value: option.id!, // 存储的值为 id
                            application: option.application,
                            groupDescription: option.groupDescription
                          } as GroupOptions))}
                          onChange={(selectedOptions) =>
                          {
                            setSelectedGroup(selectedOptions);
                            const parameters = groupList.find(group => group.id === selectedOptions?.value);
                            if(parameters?.userParameters) {
                              try {
                                const jsonObject = JSON.parse(parameters?.userParameters || '');
                                  let json = {
                                    userParametersTree: parseParametersToJsonTree(jsonObject),
                                    userParametersSource: parseParametersToTranslateTree(jsonObject)
                                  };

                                if(initialValues.userParameters) {
                                  const item = groupList.find(item => item.id === initialValues.groupId);
                                  const selected = item ? {
                                    label: item.groupName,
                                    value: item.id
                                  } as GroupOptions: null;

                                  if(selected?.value === selectedOptions?.value) {
                                    json = {
                                      userParametersSource: parseParametersToTranslateTree(jsonObject),
                                      userParametersTree: JSON.parse(initialValues.userParameters)
                                    };
                                  }
                                }
                                setUserParametersTreeData(json);
                                form.setFieldValue('groupId', selectedOptions?.value || "");
                                form.setFieldValue('conStr', parameters?.cronStr || "");
                              } catch {
                                form.setFieldValue('groupId', selectedOptions?.value || "");
                                form.setFieldValue('conStr', "");
                                form.setFieldValue('userParameters', "");

                                setUserParametersTreeData({
                                  userParametersTree: null,
                                  userParametersSource: null
                                });
                              }
                            } else {
                              form.setFieldValue('groupId', selectedOptions?.value || "");
                              form.setFieldValue('conStr', "");
                              form.setFieldValue('userParameters', "");

                              setUserParametersTreeData({
                                userParametersTree: null,
                                userParametersSource: null
                              });
                            }
                          }
                          }
                        />
                        <FormErrorMessage>
                          {form.errors.functionIds}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {selectedGroup?.groupDescription && <Box my="0.5rem" mb="1rem">
                  <Text fontSize='l' fontWeight='medium'>{t("release-digital-workforce.function.desc")}: {selectedGroup?.groupDescription}</Text>
                </Box>}
                {selectedGroup?.application !== 0 && userParametersTreeData?.userParametersTree &&
                <Box my="0.5rem">
                  <Field name="userParameters">
                  {({ field, form }: any) => (
                  <FormControl>
                    <div className="border-div">
                    <CustomEditor
                      data={userParametersTreeData?.userParametersTree}
                      source={userParametersTreeData?.userParametersSource}
                      collapsible
                      onChange={(key, parent, type, e)=>{
                        setUserParametersTreeData({
                        ...userParametersTreeData,
                        userParametersTree: e,
                      });
                    }}
                      ></CustomEditor>
                    </div>
                  </FormControl>
                )}
                  </Field>
              </Box>
                }
                {
                  selectedGroup?.application === 0 &&<>
                  <Box my="0.5rem">
                  <Field name="seller">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.seller && form.touched.seller
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("invoice.fields.seller")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          value={selectedSeller}
                          options={invoiceInfos}
                          onChange={(selectedOptions) =>
                          {
                            setSelectedSeller(selectedOptions);
                          }
                          }
                        />
                        <FormErrorMessage>
                          {form.errors.seller}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {selectedSeller && <Card mb="0.5rem">
                  <CardBody overflow={'auto'}>
                    <Box>
                      <InvoiceInfoTable data={selectedSeller ? [selectedSeller] : []}></InvoiceInfoTable>
                    </Box>
                  </CardBody>
                </Card>}
                <Box my="0.5">
                  <Text fontSize='l' as="b" >{invoiceExtra.sellerExtraTitle}:</Text>
                  <br/>
                  {_renderFormWithAccordion(invoiceExtra.sellerExtra)}
                </Box>
                  <Box my="0.5rem">
                  <Field name="buyer">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.buyer && form.touched.buyer
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("invoice.fields.buyer")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          value={selectedBuyer}
                          options={invoiceInfos}
                          onChange={(selectedOptions) =>
                          {
                            setSelectedBuyer(selectedOptions);
                          }
                          }
                        />
                        <FormErrorMessage>
                          {form.errors.buyer}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {
                  selectedBuyer && <Card>
                  <CardBody overflow={'auto'}>
                    <Box>
                      <InvoiceInfoTable data={selectedBuyer ? [selectedBuyer] : []}></InvoiceInfoTable>
                    </Box>
                  </CardBody>
                </Card>
                }
                <Card my="1rem">
                  <CardBody overflow={'auto'}>
                    <Box>
                      <Flex justifyContent="space-between">
                      <Text fontSize='l' as="b">{invoiceInfoTitle}:</Text>
                      <Flex justifyContent="space-between">
                      {selectedGroup?.application === 0 
                        &&
                      <Button size="sm" colorScheme="blue" mr={3} onClick={onPreviewInvoiceOpen}>
                        {t("invoice.preview")}
                      </Button>}
                      <Button onClick={onImportModalOpen} colorScheme="teal" variant="link">{t('invoice.import')}</Button>
                      </Flex>
                      </Flex>
                    <Table variant="simple" whiteSpace='nowrap'>
                        <Thead>
                          <Tr my=".8rem" pl="0px" color="gray.400">
                            {invoiceListFields?.map((caption, idx) => {
                              return (
                                <Th
                                  color="gray.400"
                                  key={idx}
                                  ps={idx === 0 ? "0px" : ""}
                                >
                                  {caption}
                                </Th>
                              );
                            })}
                            <Th></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {invoiceList?.map((row: any, index) => {
                            return _renderTableRow(row, index);
                          })}
                        </Tbody>
                      </Table>
                    </Box>
                  </CardBody>
                </Card>
                <Box>
                    <Text fontSize='l' as="b" >{invoiceExtra.buyerExtraTitle}:</Text>
                    <br/>
                    {_renderFormWithAccordion(invoiceExtra.buyerExtra)}
                </Box>             
                  </>
                }
                <Box my="0.5rem">
                  <Field name="executeType">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.executeType && form.touched.executeType
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("dispatch-task.trigger-type")}:
                        </FormLabel>
                        <ChakraSelect
                          colorScheme="purple"
                          options={taskType}
                          value={taskType.find(item => item.value === executeType)}
                          defaultValue={taskType[0]}
                          onChange={(selectedOption) => {
                            setExecuteType(selectedOption?.value);
                            form.setFieldValue("executeType", selectedOption?.value || 0);
                          }}
                        />
                        <FormErrorMessage>
                          {form.errors.executeType}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                {executeType === "1" && <Box my="0.5rem">
                  <Field name="cronStr">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.cronStr && form.touched.cronStr
                        }
                        isRequired
                      >
                        <FormLabel>
                        {t("dispatch-task.trigger-time")}:
                        </FormLabel>
                        <Input {...field} defaultValue={initialValues.cronStr} />
                        <FormErrorMessage>
                          {form.errors.cronStr}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>}

              </ModalBody>
              <ModalFooter>
               
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={handleClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
            <PreviewInvoice 
            isOpen={isPreviewInvoice}
            onOpen={onPreviewInvoiceOpen}
            onClose={onPreviewInvoiceClose}
            initialValues = {
              {seller: selectedSeller,
              buyer: selectedBuyer,
              formValues: props?.values,
              invoiceList: invoiceList,
              invoiceListFields: invoiceListFields}
            }></PreviewInvoice> 
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
    <ImportInvoiceList
     isOpen={isImportModal}
     onOpen={onImportModalOpen}
     onClose={onImportModalClose}
     setInvoice={setInvoiceList}
    ></ImportInvoiceList>
    </>
  );
};

export default CreateDispatchTask;
