import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import { SELECT_TYPE } from "../../../../constants/constants";

const Select = (props) => {
  const {
    marginLeft,
    marginBottom,
    onChange,
    label,
    options,
    styles,
    value,
    selectionType // 'single' or 'multiple'
  } = props;
  
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    // Initialize selected values from pipe-delimited string
    if (value && selectionType === SELECT_TYPE.MULTIPLE) {
      // Check if value is a string before splitting
      if (typeof value === 'string') {
        setSelectedValues(value.split('|'));
      } else if (Array.isArray(value)) {
        // If value is already an array, use it directly
        setSelectedValues(value); // This is just for backward compatibility, may be removed in the future
      } else {
        // For other types, convert to string first then try to split
        try {
          setSelectedValues(String(value).split('|'));
        } catch (error) {
          console.error("Failed to process value for multi-select:", error);
          setSelectedValues([]);
        }
      }
    }
  }, [value, selectionType]);

  const style = merge(
    { marginLeft: marginLeft, marginBottom: marginBottom, display: "flex" },
    styles.field
  );

  const labelStyle = merge({}, styles.label);
  const inputStyle = merge(
    {}, 
    styles.value,
    selectionType === SELECT_TYPE.MULTIPLE ? { height: "80px" } : {} // Increase height for multi-select
  );

  const handleChange = (e) => {
    if (selectionType === SELECT_TYPE.MULTIPLE) {
      // For multi-select, gather all selected options
      const selected = Array.from(e.target.selectedOptions).map(option => option.value);

      setSelectedValues(selected);
      // Join selected values with pipe character and pass to parent
      onChange({ target: { value: selected.join('|') } });
    } else {
      // For single select, just pass the selected value
      onChange(e);
    }
  };

  return (
    <div style={style}>
      <div style={labelStyle}>{label}</div>
      <select
        style={inputStyle}
        value={selectionType === SELECT_TYPE.MULTIPLE ? selectedValues : value}
        onChange={handleChange}
        multiple={selectionType === SELECT_TYPE.MULTIPLE}
      >
        {options.map((option, index) => (
          <option 
            key={index} 
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;