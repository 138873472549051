import instance from "./interceptor";

const apiPath = process.env.REACT_APP_ENV !== "development" ? '/api' : '';
export const getDispatchTasksList = async () => {
  try {
    const response = await instance.get(`${apiPath}/usertasks`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDispatchTaskDetail = async (id: string) => {
  try {
    const response = await instance.get(`${apiPath}/usertasks/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const createOrUpdateDispatchTask = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/usertasks`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const deleteDispatchTask = async (id: string) => {
  try {
    const response = await instance.delete(`${apiPath}/usertasks/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const getDispatchTasksGroups = async () => {
  try {
    const response = await instance.get(`${apiPath}/usertasks/groups`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const toggleDispatchTaskEnable = async (id: string, enable: boolean) => {
  try {
    const response = await instance.post(`${apiPath}/usertasks/${id}/${enable}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const executeDispatchTask = async (id: string) => {
  try {
    const response = await instance.post(`${apiPath}/usertasks/executetask/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const stopDispatchTask = async (id: string) => {
  try {
    const response = await instance.post(`${apiPath}/usertasks/stoptask/${id}`);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};

export const uploadInvoiceDetails = async (data: any) => {
  try {
    const response = await instance.post(`${apiPath}/usertasks/upload`, data);
    return response.data;
  } catch (error) {
    console.error("error:", error);
    throw error;
  }
};