import { Box, Heading, Tag, Text, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FiArrowUpRight } from "react-icons/fi";
import { Case } from "../../../models/case";
import { getCaseDetail } from "../../../services/caseService";
import { FiTag } from "react-icons/fi";
import { parseSingleCase } from "../../../utils/case";
import Carousel from "../../../components/Carousel";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";

const CaseDetails = () => {
  const { t } = useTranslation();
  const [caseInfo, setCaseInfo] = useState<Case>();
  const params = useParams();
  const id = params.id;
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.case-management", path: "/articles/cases" },
    { label: caseInfo?.title!, path: id! },
  ];
  useEffect(() => {
    if (!!id) {
      getCaseDetail(id).then((res) => {
        setCaseInfo(parseSingleCase(res));
      });
    }
  }, []);
  const _renderTag = (row: string, index: number) => {
    return (
      <Tag
        size="md"
        variant="solid"
        ml="0.5rem"
        my={{ base: "0.1rem", xl: 0 }}
        key={index}
      >
        {row}
      </Tag>
    );
  };

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bgColor="#fff" px={4}>
        <Heading as="h2" size="2xl" py={{ base: "1rem", md: "2rem" }}>
          {caseInfo?.title}
        </Heading>
        <Box
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          py={{ base: "1rem" }}
          color="gray.500"
        >
          <Box display="flex" alignItems="center">
            <FiTag fontSize="1rem" />
            <Text color="gray.500"> {t("case.list.tags")}:</Text>
          </Box>
          <Box display="flex" flexWrap="wrap" alignItems="center">
            {caseInfo?.tags!.map((row, index) => {
              return _renderTag(row, index);
            })}
          </Box>
        </Box>
        <Text>{caseInfo?.backgroundDesc}</Text>
        {!!caseInfo?.link && (
          <Link target="_blank" display="flex" alignItems="center">
            <Text color="teal" pr="0.5rem">
              {t("case.list.link.title")}
            </Text>
            <FiArrowUpRight />
          </Link>
        )}
        <Box px={{ md: 8 }}>
          <Box py={4}>
            <Heading as="h3" size="lg" py={2}>
              {t("case.create.challenge")}
            </Heading>
            <Text>{caseInfo?.challengeDesc}</Text>
          </Box>
          <Box py={4}>
            <Heading as="h3" size="lg" py={2}>
              {t("case.create.solution")}
            </Heading>
            <Text>{caseInfo?.solutionDesc}</Text>
          </Box>
          <Box py={4}>
            <Heading as="h3" size="lg" py={2}>
              {t("case.create.key-deliverables")}
            </Heading>
            <Text
              p={4}
              className="ck-content"
              dangerouslySetInnerHTML={{
                __html: caseInfo?.keyDeliverables || "",
              }}
            ></Text>
          </Box>
          <Box py={4}>
            {!!caseInfo?.carouselImages && (
              <Carousel images={caseInfo.carouselImages} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseDetails;
