import invoiceBuyersExtra from '../assets/data/invoice.json';

export const transferFromInvoiceUserParameters = (param: any)=> {
  try {
    const userParametersJson = JSON.parse(param);
    const buyer = userParametersJson[0]?.buyer_info[0];
  
    if(buyer) {
      const sellerObject = {
        "seller_name" : userParametersJson[0].seller_name,
        "seller_taxpayer_id": userParametersJson[0].seller_taxpayer_id,
        "seller_address": userParametersJson[0].seller_address,
        "seller_phone": userParametersJson[0].seller_phone,
        "seller_bank_name": userParametersJson[0].seller_bank_name,
        "seller_bank_account": userParametersJson[0].seller_bank_account,
      };
      const infoObject = {invoice_details: buyer.invoice_details};
      const buyerObject = {
        "buyer_name": buyer.buyer_name || '',
        "buyer_taxpayer_id": buyer.buyer_taxpayer_id || '',
        "buyer_address": buyer.buyer_address || '',
        "buyer_phone": buyer.buyer_phone || '',
        "display_buyer_contact_info": buyer.display_buyer_contact_info || false,
        "buyer_bank_name": buyer.buyer_bank_name || '',
        "buyer_bank_account": buyer.buyer_bank_account || '',
        "display_buyer_bank_info": buyer.display_buyer_bank_info || false
      };
      const extraObject = {};
      invoiceBuyersExtra.buyerExtra.map(item => {
        extraObject[item.code] = buyer[item.code] || '';
        return item;
      });
      invoiceBuyersExtra.sellerExtra.map(item => {
        extraObject[item.code] = userParametersJson[0][item.code] || '';
        return item;
      });
  
      return {
        sellerObject,
        buyerObject,
        infoObject,
        extraObject,
      };
    }
    return {
      sellerObject: null,
      buyerObject: null,
      infoObject: null,
      extraObject: null,
    };
  } catch(e) {
    console.log(e);
  } 
  return {
    sellerObject: undefined,
    buyerObject: undefined,
    infoObject: undefined,
    extraObject: undefined,
  };
}

export const isNumeric = (val: string) => {
 return /^[+-]?\d+(\.\d+)?$/.test(val);
}