import React, { useEffect } from "react";
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Card,
  CardBody,
  Text,
  GridItem,
  Grid,
  CardHeader,
} from "@chakra-ui/react";
import _ from "lodash";
import "react-datetime/css/react-datetime.css";
import { InvoiceInfoItem } from "../../../../models/invoice";
import { isNumeric } from "../../../../utils/invoice";
import { formatFloatNumber, parsePercent } from "../../../../utils/common";

interface PreviewFormProps {
  isOpen: boolean;
  initialValues: {
    seller?: InvoiceInfoItem | null,
    buyer?: InvoiceInfoItem | null,
    formValues?: any,
    invoiceList: any[];
    invoiceListFields: string[];
  };
  onOpen: () => void;
  onClose: () => void;
}



const PreviewInvoice: React.FC<PreviewFormProps> = ({
  isOpen,
  initialValues,
  onOpen,
  onClose,
}) => {
   const _renderTableRow = (row: any, index: number) => {
    const untaxedPrice = isNumeric(row["item_total_price"]) && isNumeric(row["tax_rate"]) 
    ? parseFloat(row["item_total_price"]) / (1 + parseFloat(row["tax_rate"]))
    : isNumeric(row["item_total_price"]) && !isNumeric(row["tax_rate"]) 
    ? parseFloat(row["item_total_price"])
    : 0;
    const tax =isNumeric(row["tax_rate"]) 
    ? untaxedPrice * parseFloat(row["tax_rate"])
    : 0;
      return (
       <>
        <Tr key={index}>
          {_.keys(row).map(key => {
            let value = row[key];
            if(key === 'tax_rate' && (isNumeric(row[key]))) {
              value = parsePercent(row[key]);
            } else if(key === 'item_total_price') {
              value = formatFloatNumber(untaxedPrice);
            } else if (key === 'item_price_with_tax') {
              if(untaxedPrice && row['item_quantity']) {
                value = formatFloatNumber(untaxedPrice / parseFloat(row['item_quantity']));
              }
            }

            if(!value) {
              return <Td border="none" paddingY="2px" paddingLeft="0"><Text fontSize="sm" align="center"></Text></Td>
            }
            return <Td border="none" paddingY="2px" paddingLeft="0"><Text fontSize="sm" align="center">{value}</Text></Td>
          })}
          <Td border="none" paddingY="2px">
          <Text fontSize="sm" align="center">{formatFloatNumber(tax)}</Text>
          </Td>      
        </Tr>
        </>
      );
    };

  const mappingChineseAmount = (val: number) => {
    if (val) {
      const valArray: string[] = val.toString().split('.');
      const integerPart = valArray.length ? valArray[0].split('') : [];
      const decimalPart = valArray.length > 1 ? valArray[1].split('') : [];
      const chineseNumber = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
      const integerUnit = ['仟', '佰', '拾', '亿', '仟', '佰', '拾', '万', '仟', '佰', '拾', ''];
      const decimalUnit = ['角', '分'];
      const integerResult = _.map(integerPart, (integer, idx) => {
        if (!parseFloat(integer)) {
          return '';
        }
        return (
          chineseNumber[parseFloat(integer)] +
          integerUnit[integerUnit.length - integerPart.length + idx]
        );
      }).join('');
      const decimalResult = _.map(decimalPart, (decimal, idx) => {
        return chineseNumber[parseFloat(decimal)] + decimalUnit[idx];
      }).join('');
      return integerResult + '元' + decimalResult + '整';
    }
    return '';
  }

  const totalWithoutTax = _.sum(initialValues.invoiceList?.map(item => {
    const untaxedPrice = isNumeric(item.item_total_price) ? parseFloat(item.item_total_price) : 0;
    // const tax = isNumeric(item.item_total_price) && isNumeric(item.tax_rate) 
    // ? parseFloat(item.item_total_price) * parseFloat(item.tax_rate)
    // : 0;
    return untaxedPrice;
  }));

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "70rem" }}>
        <ModalHeader>
          发票预览
        </ModalHeader>
        <ModalCloseButton />
        <Card mb="1rem">
          <CardHeader>
            <Text fontSize='2xl' color="#986343" align="center">电子发票（{initialValues.formValues.invoice_type}）</Text>
          </CardHeader>
          <CardBody overflow={'auto'}>
            <Box>
            <Text fontSize='l' as="b"></Text>  
            <Grid border={"1px solid #986343"} templateColumns="repeat(24, 1fr)">
              <GridItem border={"1px solid #986343"} colSpan={1}>
                <Text color="#986343" fontSize="sm" style={{writingMode: 'vertical-lr', textOrientation: 'upright', margin: '8px auto'}}>购买方信息</Text>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={11} display={'flex'} flexDirection={"column"} justifyContent={"center"} padding="8px">
                <Flex><Text color="#986343" fontSize="sm">名称：</Text><Text fontSize='sm'>{initialValues.buyer?.name}</Text></Flex>
                <Flex><Text color="#986343" fontSize="sm">统一社会信用代码/纳税人识别号：</Text><Text fontSize='sm'>{initialValues.buyer?.usci}</Text></Flex>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={1}><Text color="#986343" fontSize="sm" style={{writingMode: 'vertical-lr', textOrientation: 'upright', margin: '8px auto'}}>销售方信息</Text></GridItem>
              <GridItem border={"1px solid #986343"} colSpan={11} display={'flex'} flexDirection={"column"} justifyContent={"center"} padding="8px">
                <Flex><Text color="#986343" fontSize="sm">名称：</Text><Text  fontSize='sm'>{initialValues.seller?.name}</Text></Flex>
                <Flex><Text color="#986343" fontSize="sm">统一社会信用代码/纳税人识别号：</Text><Text  fontSize='sm'>{initialValues.seller?.usci}</Text></Flex>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={24}>
                <Box height={"300px"} overflowY="auto">
                <Table variant="simple" whiteSpace='nowrap'>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="#986343">
                    {initialValues.invoiceListFields?.map((caption, idx) => {
                      return (
                        <Th
                          key={idx}
                          ps={idx === 0 ? "0px" : ""}
                          fontWeight="normal"
                        >
                          <Text fontSize="sm" color="#986343" align="center">{caption}</Text>                          
                        </Th>
                      );
                    })}
                    <Th
                    key={'rate'}
                    fontWeight="normal"                    
                    >
                    <Text fontSize="sm" color="#986343" align="center">税额</Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {initialValues.invoiceList?.map((row: any, index) => {
                    return _renderTableRow(row, index);
                  })}
                </Tbody>
              </Table>
              </Box>                
              <Table variant="simple" whiteSpace='nowrap'>
                <Tbody>
                <Tr>
                  <Td fontWeight="normal" paddingLeft="0">
                  <Text fontSize="sm" color="#986343" align="center">合计</Text> 
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td><Text fontSize="sm">¥{totalWithoutTax}</Text></Td>
                  <Td></Td>
                </Tr>
                </Tbody>
              </Table>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={4}><Text color="#986343" fontSize="sm" align="center">价税合计（大写）</Text></GridItem>
              <GridItem border={"1px solid #986343"} colSpan={20}>
                <Flex justifyContent='space-between'>
                  <Text fontSize="sm" paddingLeft="8px">{mappingChineseAmount(initialValues.formValues.total_amount_with_tax || 0)}</Text>
                  <Flex paddingRight="200px"><Text color="#986343" fontSize="sm">（小写）¥</Text><Text fontSize="sm">{initialValues.formValues.total_amount_with_tax || 0}</Text></Flex>
                  </Flex>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={1} display="flex" justifyContent="center" alignItems="center">
                <Text color="#986343" fontSize="sm" style={{writingMode: 'vertical-lr', textOrientation: 'upright'}}>备注</Text>
              </GridItem>
              <GridItem border={"1px solid #986343"} colSpan={23} minHeight="150px" padding="10px" display="flex" flexDir="column" rowGap="10px">
                {
                  initialValues.buyer?.displayAddress && 
                  <Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">购买方地址：</Text><Text  fontSize='sm'>{initialValues.buyer?.address}</Text></Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">电话：</Text><Text  fontSize='sm'>{initialValues.buyer?.phone}</Text></Flex>
                  </Flex>
                }
                {
                  initialValues.buyer?.displayBankAccount && 
                  <Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">购方开户银行：</Text><Text  fontSize='sm'>{initialValues.buyer?.bankName}</Text></Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">银行账号：</Text><Text  fontSize='sm'>{initialValues.buyer?.bankAccount}</Text></Flex>
                  </Flex>
                }
                {
                  initialValues.buyer?.displayAddress && 
                  <Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">销售方地址：</Text><Text  fontSize='sm'>{initialValues.seller?.address}</Text></Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">电话：</Text><Text  fontSize='sm'>{initialValues.seller?.phone}</Text></Flex>
                  </Flex>
                }
                {
                  initialValues.seller?.displayBankAccount &&
                  <Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">销方开户银行：</Text><Text  fontSize='sm'>{initialValues.seller?.bankName}</Text></Flex>
                    <Flex flexBasis="300px"><Text color="#986343" fontSize="sm">银行账号：</Text><Text  fontSize='sm'>{initialValues.seller?.bankAccount}</Text></Flex>
                  </Flex>
                }
                
                <Flex><Text  fontSize='sm' style={{ whiteSpace: "pre-line" }}>{initialValues.formValues.remarks}</Text></Flex>                         
              </GridItem>
            </Grid>           
            <Text color="#986343" fontSize="sm" margin="20px">开票人</Text>
            </Box>
          </CardBody>
        </Card>
      </ModalContent>
    </Modal>
  );
};

export default PreviewInvoice;
