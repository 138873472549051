import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { InvoiceInfoItem } from "../../../../models/invoice";

interface InvoiceInfoProps {
  data: InvoiceInfoItem[];
}
const InvoiceInfoTable: React.FC<InvoiceInfoProps> = ({ data }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();

  const tableFiled = [
    t("invoice.info.fields.name"),
    t("invoice.info.fields.usci"),
    t("invoice.info.fields.address"),
    t("invoice.info.fields.phone"),
    t("invoice.info.fields.displayAddress"),
    t("invoice.info.fields.bankName"),
    t("invoice.info.fields.bankAccount"),
    t("invoice.info.fields.displayBankAccount"),
  ];

  const _renderCell = (
      row: InvoiceInfoItem,
      text: any,
      fontSize: string,
      fontWeight: string,
      extraProps?: any
    ) => {
      return (
        <Td
          py="0.5rem"
          {...extraProps}
          pl="0.2rem"
        >
          <Text fontSize={fontSize} fontWeight={fontWeight}>
            {text}
          </Text>
        </Td>
      );
    };
  
    const showOrHide = (value: boolean) => {
      if(value) {
        return t('invoice.info.fields.show');
      } else {
        return t('invoice.info.fields.hide');
      }
    }
  
    const _renderTableRow = (row: InvoiceInfoItem, index: number) => {
      return (
        <Tr
          key={index}
          _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
        >
          {_renderCell(row, row.name, "md", "normal")}
          {_renderCell(row, row.usci, "md", "normal")}
          {_renderCell(row, row.address, "md", "normal")}
          {_renderCell(row, row.phone, "md", "normal")}
          {_renderCell(row, showOrHide(row.displayAddress), "md", "normal")}
          {_renderCell(row, row.bankName, "md", "normal")}
          {_renderCell(row, row.bankAccount, "md", "normal")}
          {_renderCell(row, showOrHide(row.displayBankAccount), "md", "normal")}
        </Tr>
      );
    };

  return (
    <Box>
      <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row: InvoiceInfoItem, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
    </Box>
  );
};

export default InvoiceInfoTable;
