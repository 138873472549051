import { AspectRatio, Box, Heading, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { isPlatformAdmin } from "../../utils/auth";

const Workbench = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const items = [
    {
      title: t("menu.my-workforce"),
      path: "/workbench/digital-workforce",
    },
    {
      title: t("menu.dispatch"),
      path: "/workbench/dispatch",
    },  
    {
      title: t("menu.project-marketplace"),
      path: "/workbench/project-marketplace",
    },
    {
      title: t("menu.blogs"),
      path: "/workbench/blogs",
    },
    {
      title: t("menu.organization-management"),
      path: "/workbench/organization",
    },
    {
      title: t("menu.invoice-management"),
      path: "/workbench/invoice",
    },
    {
      title: t("menu.job-logs-management"),
      path: "/workbench/log",
    },
  ];

  if (isPlatformAdmin()) {
    items.push({
      title: t("menu.release-digital-workforce"),
      path: "/workbench/release-digital-workforce",
    });
  }
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
  ];

  return (
    <Box maxW="1240px" margin="0 auto" p="1rem">
      <Box pb="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box minH="calc(100vh - 120px)">
        <Heading textAlign="center" mb={{ base: "2rem", md: "5rem" }}>
          {t("menu.workbench")}
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
          {items.map((item, key) => (
            <AspectRatio ratio={1} key={key}>
              <Box
                bg="#fff"
                borderRadius="md"
                p="1rem"
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => navigate(item.path)}
                boxShadow="small"
                transition="transform 0.3s"
                _hover={{
                  boxShadow: "lg",
                  rounded: "md",
                  cursor: "pointer",
                  transform: "translateY(-6px)",
                  color: "teal",
                }}
              >
                <Heading size="lg">{item.title}</Heading>
              </Box>
            </AspectRatio>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default Workbench;
