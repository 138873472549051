import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Flex,
  Switch,
  Tag
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { deleteDispatchTask, executeDispatchTask, getDispatchTasksList, stopDispatchTask, toggleDispatchTaskEnable } from "../../../../services/dispatchTasksService";
import { DispatchTask, EditDispatchTaskForm } from "../../../../models/dispatchTask";
import CreateDispatchTask from "../Create";
import { dateToLocalTime } from "../../../../utils/common";
import { transferFromInvoiceUserParameters } from "../../../../utils/invoice";

interface DispatchProps {
  isEdit: boolean;
}
const DispatchTasksList: React.FC<DispatchProps> = ({ isEdit }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const toast = useToast();
  const [tableList, setTableList] = useState<DispatchTask[]>([]);
  const [deleteId, setDeleteId] = useState<string>();
  const [initValue, setInitValue] =
  useState<EditDispatchTaskForm>({'taskName': ''});

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const tableFiled = [
    t("dispatch-task.name"),
    t("dispatch-task.trigger-type"),
    // t("dispatch-task.created-at"),
    t("dispatch-task.status"),
    t("dispatch-task.expected-start"),
    t("dispatch-task.start"),
    t("dispatch-task.end"),
  ];

  if (isEdit) {
    tableFiled.push(t("common.btn.actions"));
  }

  useEffect(() => {
    fetchDispatchTasksList();
  }, []);

  const fetchDispatchTasksList = () => {
    getDispatchTasksList().then((res) => {
      setTableList(res);
    });
  };
  const handleDeleteTask= () => {
    deleteDispatchTask(deleteId!)
      .then((res) => {
        fetchDispatchTasksList();
        onDeleteModalClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const _renderCell = (
    row: DispatchTask,
    text: any,
    fontSize: string,
    fontWeight: string,
    highlight?: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
      >
        {highlight ?
        <Tag colorScheme={highlight}>
          <Text fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Text>
        </Tag>
        :
        <Text fontSize={fontSize} fontWeight={fontWeight}>
        {text}
      </Text>
        }
        
      </Td>
    );
  };

  const getStatus = (status: any) => {
    if(status.toString() === '-1') {
      return t(`dispatch-task.statuses.pending`)
    }
    return t(`dispatch-task.statuses.values.${status}`)
  }

  const _renderTableRow = (row: DispatchTask, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.taskName, "sm", "bold", "", { minW: "200px" })}
        {_renderCell(row, t(`dispatch-task.types.${row.executeType}`) , "sm", "normal")}
        {/* {_renderCell(row, dateToLocalTime(row.createdDate!), "sm", "normal")} */}
        {_renderCell(row, getStatus(row.taskExecutionResultsStatus),  "sm", "normal", row.taskExecutionResultsStatus === 1 ? "blue" : "")}
        {_renderCell(row, dateToLocalTime(row.exceptStartDate!), "sm", "normal")}
        {_renderCell(row, dateToLocalTime(row.startDate!), "sm", "normal")}
        {_renderCell(row, dateToLocalTime(row.finishDate!), "sm", "normal")}
        <Td>
        <Switch  minW="100px" disabled={row.executeType === 0} defaultChecked={row.executeType === 1 && row.enable} colorScheme="teal" onChange={e => {
          toggleDispatchTaskEnable(row.id!, e.target.checked).then(res => {
            toast({
              title: t("common.btn.operate-success-msg"),
              position: "top",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            fetchDispatchTasksList();
            })
            .catch(err=>{
              toast({
                title: t(`common.btn.operate-error-msg,${err}`),
                position: "top",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
        }}>{t('common.enable')}</Switch>
        </Td>
        {isEdit && (
          <>
            <Td pl="0.2rem">
              <Flex>
              {row.taskExecutionResultsStatus === 1 &&
              <Button
                size="sm"
                colorScheme="teal"
                mr="0.5rem"
                onClick={()=>{
                  stopDispatchTask(row.id!).then(()=>{
                    toast({
                      title: t("common.btn.operate-success-msg"),
                      position: "top",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    });
                    fetchDispatchTasksList();
                  }).catch(err=>{
                    toast({
                      title: t(`common.btn.operate-error-msg,${err}`),
                      position: "top",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    });
                  })
                }}
              >
                  {t("common.btn.stop")}
                </Button>}
                <Button 
                  size="sm"
                  colorScheme="teal"
                  mr="0.5rem"
                  disabled={row.taskExecutionResultsStatus ===1}
                  onClick={()=>{
                    executeDispatchTask(row.id!).then(()=>{
                      toast({
                        title: t("common.btn.operate-success-msg"),
                        position: "top",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                      });
                      fetchDispatchTasksList();
                    }).catch(err=>{
                      toast({
                        title: t(`common.btn.operate-error-msg,${err}`),
                        position: "top",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                      });
                    })
                  }}
                >
                  {t("common.btn.execute")}
                </Button>
                <Button
                    size="sm"
                    colorScheme="teal"
                    mr="0.5rem"
                    disabled={row.taskExecutionResultsStatus === 1}
                    onClick={() => {
                      const {sellerObject, buyerObject, extraObject } = transferFromInvoiceUserParameters(row.userParameters || '');
                      if(buyerObject) {
                        const  initialValues = Object.assign(row, sellerObject, buyerObject, extraObject);
                        setInitValue(initialValues);
                      } else {
                       setInitValue(row);
                      }
                      
                      onCreateModalOpen();
                    }}
                    >
                    {t("common.btn.edit")}
                </Button>
                <Button
                    size="sm"
                    colorScheme="red"
                    disabled={row.taskExecutionResultsStatus === 1}
                    onClick={() => {
                      setDeleteId(row.id);
                      onDeleteModalOpen();
                    }}
                  >
                    {t("common.btn.delete")}
                  </Button>
              </Flex>
            </Td>
          </>
        )}
      </Tr>
    );
  };

  return (
    <Box>
      {isEdit && (
        <Box mb="1rem" p="1rem" bgColor="#fff">
          <Button
            leftIcon={<FiPlus />}
            colorScheme="teal"
            variant="solid"
            size="md"
            onClick={() => {
                setInitValue({
                  taskName: ''
                });
                onCreateModalOpen();
            }}
          >
            {t("dispatch-task.create")}
          </Button>
        </Box>
      )}

      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: DispatchTask, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> {t("dispatch-task.delete-modal.title")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{t("dispatch-task.delete-modal.content")}</ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onDeleteModalClose} size="sm">
              {t("common.btn.cancel")}
            </Button>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleDeleteTask()}
            >
              {t("common.btn.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CreateDispatchTask
        isOpen={isCreateModalOpen}
        onOpen={onCreateModalOpen}
        initialValues={initValue}
        onClose={()=>{
          fetchDispatchTasksList();
          onCreateModalClose();
        }}
        onSubmit={fetchDispatchTasksList}
      />
    </Box>
  );
};

export default DispatchTasksList;
