import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormErrorMessage,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import "react-datetime/css/react-datetime.css";
import { InvoiceInfoItem } from "../../../../../models/invoice";
import { createInvoiceInfo } from "../../../../../services/invoiceService";


interface AddFormProps {
  isOpen: boolean;
  initialValues: InvoiceInfoItem;
  onOpen: () => void;
  onClose: () => void;
  onSubmit: () => void;
}

const CreateInvoiceInfo: React.FC<AddFormProps> = ({
  isOpen,
  initialValues,
  onOpen,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const handleCreate = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    let opt = "create";
    if (!isEmpty(values.id)) {
      opt = "update";
    }
    createInvoiceInfo(values)
      .then((res) => {
        onClose();
        onSubmit();
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      })
      .catch((error) => {
        toast({
          title: error?.response?.data,
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "50rem" }}>
        <ModalHeader>
          {t("invoice.info.fields.title")}
        </ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreate}
        >
          {(props) => (
            <Form>
              <ModalBody>
                 <Box my="0.5rem">
                  <Field name="name">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.name && form.touched.name
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("invoice.info.fields.name")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.name}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="usci">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.usci && form.touched.usci
                        }
                        isRequired
                      >
                        <FormLabel>
                          {t("invoice.info.fields.usci")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.usci}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="displayAddress">
                    {({ field, form }: any) => (
                      <FormControl
                      display="flex"
                      justifyContent="space-between"
                      >
                        <FormLabel>
                          {t("invoice.info.fields.address")}:
                        </FormLabel>
                        <Checkbox defaultChecked={field.value} colorScheme='cyan' onChange={(e) => {form.setFieldValue('displayAddress', e.target.checked)}}>
                        {t("invoice.info.fields.displayAddress")}
                          </Checkbox>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="address">
                    {({ field, form }: any) => (
                      <FormControl
                      >
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.address}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="phone">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.phone && form.touched.phone
                        }
                      >
                        <FormLabel>
                          {t("invoice.info.fields.phone")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.phone}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                <Field name="displayBankAccount">
                    {({ field, form }: any) => (
                      <FormControl
                      display="flex"
                      justifyContent="space-between"
                      >
                        <FormLabel>
                          {t("invoice.info.fields.bankName")}:
                        </FormLabel>
                        <Checkbox defaultChecked={field.value} colorScheme='cyan' onChange={(e) => {form.setFieldValue('displayBankAccount', e.target.checked)}}>
                        {t("invoice.info.fields.displayBankAccount")}
                          </Checkbox>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bankName">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.bankName && form.touched.bankName
                        }
                      >
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.bankName}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
                <Box my="0.5rem">
                  <Field name="bankAccount">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.bankAccount && form.touched.bankAccount
                        }
                      >
                        <FormLabel>
                          {t("invoice.info.fields.bankAccount")}:
                        </FormLabel>
                        <Input {...field} />
                        <FormErrorMessage>
                          {form.errors.bankAccount}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                </Box>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" size="sm" colorScheme="teal" mr={3}>
                  {t("common.btn.submit")}
                </Button>
                <Button size="sm" onClick={onClose}>
                  {t("common.btn.cancel")}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CreateInvoiceInfo;
