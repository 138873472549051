import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  RadioGroup,
  Stack,
  Radio,
  Text,
  useColorModeValue,
  useSteps,
  StepSeparator,
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepNumber,
  StepTitle,
  StepDescription,
  useToast,
  Tooltip,
  Tag,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { Select as ChakraSelect, MultiValue } from "chakra-react-select";
import {
  FiChevronLeft,
  FiFileText,
  FiCheck,
} from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { caseValidationSchema } from "../../../utils/validation";
import UploadImage from "../../../components/Upload";
import {
  arrayToOptions,
  getCurrentOrganizationName,
  objectToFormData,
} from "../../../utils/common";
import { createOrUpdateCase } from "../../../services/caseService";
import { isEmpty } from "lodash";
import { userParameter } from "../../../services/authService";
import { Case, CaseImage } from "../../../models/case";
import _ from "lodash";
import { categoryList, tagList } from "../../../constants/constants";
import { Options } from "../../../models/common";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";

interface CreateCaseProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onConfirm: () => void;
  initValues: Case;
}

const steps = [
  { title: "Step 1", description: "Basic Info" },
  { title: "Step 2", description: "Details" },
  { title: "Step 3", description: "Resources" },
];

const CreateCase: React.FC<CreateCaseProps> = (props) => {
  const { onClose, onConfirm, initValues } = props;
  const [editorData, setEditorData] = useState("");
  const [selectedTags, setSelectedTags] = useState<Options[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<Options[]>([]);

  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedLongImage, setSelectedLongImage] = useState<File | null>(null);
  const [deletedImages, setDeletedImages] = useState<CaseImage[]>([]);

  const { t } = useTranslation();
  const textColor = useColorModeValue("gray.700", "white");
  const toast = useToast();

  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 0,
    count: steps.length,
  });

  const Editor: any = ClassicEditor;

  useEffect(() => {
    initEditValues(initValues);
    userParameter()
      .then((res) => {})
      .catch();
  }, [initValues]);

  const initEditValues = (values: Case) => {
    if (values.id) {
      if (!isEmpty(values.keyDeliverables)) {
        setEditorData(values.keyDeliverables);
      }

      if (!isEmpty(values.categories)) {
        setSelectedCategories(arrayToOptions(values.categories));
      }
      if (!isEmpty(values.tags)) {
        setSelectedTags(arrayToOptions(values.tags));
      }
    }
  };

  const handleCreateCase = (
    values: any,
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    const editData: any = {
      title: values.title,
      backgroundDesc: values.backgroundDesc,
      caseStatus: values.caseStatus,
      link: values?.link,
      challengeDesc: values?.challengeDesc,
      solutionDesc: values?.solutionDesc,
      status: values?.status,
      ownedBy: getCurrentOrganizationName(),
    };
    let opt = "create";
    if (!isEmpty(values.id)) {
      editData.id = values.id;
      opt = "update";
    }
    if (!isEmpty(values.startDate)) {
      editData.startDate = values.startDate;
    }
    if (!isEmpty(values.endDate)) {
      editData.endDate = values.endDate;
    }
    editData.keyDeliverables = editorData;
    if (!isEmpty(selectedImages)) {
      editData.images = selectedImages;
    }
    if (!!selectedLongImage) {
      editData.longImage = selectedLongImage;
    }
    if (!isEmpty(deletedImages)) {
      editData.deleteImages = _.uniqWith(deletedImages, _.isEqual);
    }

    if (!isEmpty(selectedCategories)) {
      editData.categories = selectedCategories.map((option) => option.value);
    }
    if (!isEmpty(selectedTags)) {
      editData.tags = selectedTags.map((option) => option.value);
    }

    createOrUpdateCase(objectToFormData(editData))
      .then(() => {
        toast({
          title: t("common.btn." + opt + "-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
        onConfirm();
        formikHelpers.setSubmitting(false);
      })
      .catch(() => {
        toast({
          title: t("common.btn." + opt + "-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
        formikHelpers.setSubmitting(false);
      });
  };

  const handleUploadImages = (fileList: File[]) => {
    if (!isEmpty(fileList)) {
      setSelectedImages(fileList);
    }
  };
  const handleUploadLongImage = (fileList: File[]) => {
    if (!isEmpty(fileList)) {
      setSelectedLongImage(fileList[0]);
    }
  };

  const handledDeleteImages = (images: CaseImage[]) => {
    setDeletedImages([...deletedImages, ...images]);
  };

  const handledDeleteLongImages = (images: CaseImage[]) => {
    setDeletedImages([...deletedImages, ...images]);
  };

  const onReady = (editor: any) => {
    // 编辑器准备就绪后的回调
    console.log("Editor is ready to use!", editor);
  };

  const onEditorChange = (event: any, editor: any) => {
    // 编辑器内容发生变化的回调
    const data = editor.getData();
    setEditorData(data);
  };
  const onCategoriesChange = (value: any) => {
    setSelectedCategories(value);
  };

  const onTagsChange = (
    selectedOptions: MultiValue<Options>,
    field: { name: any },
    form: { setFieldValue: (arg0: any, arg1: any) => void }
  ) => {
    setSelectedTags(selectedOptions as Options[]);
    form.setFieldValue(field.name, selectedOptions);
  };

  const onError = (error: any) => {
    // 编辑器发生错误的回调
    console.error("Something went wrong:", error);
  };
  return (
    <Box>
      <Button
        leftIcon={<FiChevronLeft />}
        colorScheme="teal"
        variant="outline"
        size="md"
        mb="1rem"
        onClick={() => {
          onClose();
        }}
      >
        {t("common.btn.back")}
      </Button>

      <Box bgColor="#fff" padding="1rem">
        <Text fontSize="xl" mb="1rem" color={textColor} fontWeight="bold">
          {initValues?.id ? t("case.list.update") : t("case.list.create")}
        </Text>
        <Stepper index={activeStep}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
      </Box>
      <Box bgColor="#fff" padding="1rem">
        <Formik
          initialValues={initValues}
          validationSchema={caseValidationSchema}
          onSubmit={handleCreateCase}
          //如何根据不同按钮提交不同的数据
        >
          {(props) => (
            <Form>
              {activeStep === 0 && (
                <Box>
                  <Heading
                    size="md"
                    bgColor="purple"
                    color="#fff"
                    p="0.5rem"
                    borderRadius="4px"
                  >
                    {t("case.create.info")}
                  </Heading>
                  <Box display={{ md: "flex" }}>
                    <Box
                      w={{ base: "100%", md: initValues?.id ? "100%" : "60%" }}
                    >
                      <Box my="0.5rem">
                        <Field name="title">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.title && form.touched.title
                              }
                              isRequired
                            >
                              <FormLabel>
                                {t("case.create.case-title")} :
                              </FormLabel>
                              <Input {...field} />
                              <FormErrorMessage>
                                {form.errors.title}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box my="0.5rem">
                        <Field name="backgroundDesc" my="0.5rem">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.backgroundDesc &&
                                form.touched.backgroundDesc
                              }
                              isRequired
                            >
                              <FormLabel>
                                {t("case.create.case-desc")} :
                              </FormLabel>
                              <Textarea {...field} minH="10rem" />
                              <FormErrorMessage>
                                {form.errors.backgroundDesc}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box my="0.5rem">
                        <Field name="status">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.status && form.touched.status
                              }
                            >
                              <FormLabel>{t("case.create.status")} :</FormLabel>
                              <RadioGroup
                                name="status"
                                {...field}
                                defaultValue="Open"
                              >
                                <Stack spacing={5} direction="row">
                                  <Radio
                                    {...field}
                                    colorScheme="red"
                                    value="Closed"
                                  >
                                    Closed
                                  </Radio>
                                  <Radio
                                    {...field}
                                    colorScheme="green"
                                    value="Open"
                                  >
                                    Open
                                  </Radio>
                                </Stack>
                              </RadioGroup>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box my="0.5rem">
                        <Field name="categories">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.categories &&
                                form.touched.categories
                              }
                            >
                              <FormLabel>
                                {t("case.create.categories")} :
                              </FormLabel>
                              <ChakraSelect
                                isMulti
                                colorScheme="purple"
                                options={arrayToOptions(categoryList)}
                                value={selectedCategories}
                                onChange={onCategoriesChange}
                              />
                              <FormErrorMessage>
                                {form.errors.categories}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box my="0.5rem">
                        <Field name="tags">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={form.errors.tags && form.touched.tags}
                              isRequired
                            >
                              <FormLabel>{t("case.create.tags")} :</FormLabel>
                              <ChakraSelect
                                isMulti
                                colorScheme="purple"
                                options={arrayToOptions(tagList)}
                                value={selectedTags}
                                onChange={(selectedOptions) =>
                                  onTagsChange(selectedOptions, field, form)
                                }
                              />
                              <FormErrorMessage>
                                {form.errors.tags}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Box>
                      <Box>
                        <Field name="link">
                          {({ field, form }: any) => {
                            const value =
                              field.value === "undefined" ? "" : field.value;
                            return (
                              <FormControl
                                isInvalid={
                                  form.errors.link && form.touched.link
                                }
                              >
                                <FormLabel>{t("case.create.link")} :</FormLabel>
                                <Input {...field} value={value} size="md" />
                                <FormErrorMessage>
                                  {form.errors.link}
                                </FormErrorMessage>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </Box>
                      <Box display={{ md: "flex" }} my="0.5rem">
                        <Box mr={{ md: "2rem" }}>
                          <Field name="startDate">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.startDate &&
                                  form.touched.startDate
                                }
                              >
                                <FormLabel>
                                  {t("case.create.start-date")} :
                                </FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Select Start Date"
                                  minW="200px"
                                  type="date"
                                />
                                <FormErrorMessage>
                                  {form.errors.startDate}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Box>
                        <Box>
                          <Field name="endDate">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.endDate && form.touched.endDate
                                }
                              >
                                <FormLabel>
                                  {t("case.create.end-date")} :
                                </FormLabel>
                                <Input
                                  {...field}
                                  placeholder="Select End Date"
                                  size="md"
                                  minW="200px"
                                  type="date"
                                />
                                <FormErrorMessage>
                                  {form.errors.endDate}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "0%", md: initValues?.id ? "0%" : "40%" }}
                      display={{ base: "none", md: "flex" }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {!initValues?.id && <Step1></Step1>}
                    </Box>
                  </Box>
                </Box>
              )}
              {activeStep === 1 && (
                <Box>
                  <Heading
                    size="md"
                    bgColor="purple"
                    color="#fff"
                    p="0.5rem"
                    borderRadius="2px"
                  >
                    {t("case.create.details")}
                  </Heading>
                  <Box display={{ md: "flex" }}>
                    <Box
                      w={{ base: "100%", md: initValues?.id ? "100%" : "60%" }}
                    >
                      <Box p="0.5rem" mt="0.5rem" borderRadius="2px">
                        <Box mb="1rem">
                          <Heading
                            size="sm"
                            bgColor="cyan.300"
                            color="#fff"
                            p="0.3rem"
                            borderRadius="4px"
                          >
                            {t("case.create.challenge")}
                          </Heading>
                          <Box>
                            <Box>
                              <Field name="challengeDesc">
                                {({ field, form }: any) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.challengeDesc &&
                                      form.touched.challengeDesc
                                    }
                                  >
                                    <FormLabel>
                                      {t("case.create.desc")} :
                                    </FormLabel>
                                    <Textarea {...field} minH="10rem" />
                                    <FormErrorMessage>
                                      {form.errors.challengeDesc}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                          </Box>
                        </Box>
                        <Box mb="1rem">
                          <Heading
                            size="sm"
                            bgColor="cyan.300"
                            color="#fff"
                            p="0.3rem"
                            borderRadius="4px"
                          >
                            {t("case.create.solution")}
                          </Heading>
                          <Box>
                            <Box>
                              <Field name="solutionDesc">
                                {({ field, form }: any) => (
                                  <FormControl
                                    isInvalid={
                                      form.errors.solutionDesc &&
                                      form.touched.solutionDesc
                                    }
                                  >
                                    <FormLabel>
                                      {t("case.create.desc")} :
                                    </FormLabel>
                                    <Textarea {...field} minH="10rem" />
                                    <FormErrorMessage>
                                      {form.errors.solutionDesc}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Field>
                            </Box>
                          </Box>
                        </Box>
                        <Box>
                          <Heading
                            size="sm"
                            bgColor="cyan.300"
                            color="#fff"
                            p="0.3rem"
                            borderRadius="4px"
                          >
                            {t("case.create.key-deliverables")}
                          </Heading>
                          <Box>
                            <Box>
                              <FormLabel>{t("case.create.desc")} :</FormLabel>
                              <Box
                                maxH={{ base: "17rem", md: "12rem" }}
                                overflow="auto"
                              >
                                <CKEditor
                                  editor={Editor}
                                  data={editorData}
                                  onReady={onReady}
                                  onChange={onEditorChange}
                                  onError={onError}
                                  config={{
                                    toolbar: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "link",
                                      "bulletedList",
                                      "numberedList",
                                      "alignment",
                                      "font",
                                    ],
                                    // language: 'zh-cn',
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "0%", md: initValues?.id ? "0%" : "40%" }}
                      display={{ base: "none", md: "flex" }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {!initValues?.id && <Step2></Step2>}
                    </Box>
                  </Box>
                </Box>
              )}
              {activeStep === 2 && (
                <Box>
                  <Heading
                    size="md"
                    bgColor="purple"
                    color="#fff"
                    p="0.5rem"
                    borderRadius="4px"
                  >
                    {t("case.create.resources")}
                  </Heading>

                  <Box display={{ md: "flex" }}>
                    <Box
                      w={{ base: "100%", md: initValues?.id ? "100%" : "60%" }}
                    >
                      <Box my="0.5rem">
                        <FormLabel>
                          {t("case.create.images")}
                          <Tooltip
                            hasArrow
                            label={t("case.create.images-tip")}
                            bg="gray.300"
                            color="black"
                            px="2px"
                            display={{ base: "none", md: "block" }}
                          >
                            <Tag mx="4px">
                              <FiInfo />
                            </Tag>
                          </Tooltip>
                          :
                        </FormLabel>
                        <UploadImage
                          size="100px"
                          rounded="md"
                          maxUploadCount={15}
                          onUpdateFile={handleUploadImages}
                          onDeleteImage={handledDeleteImages}
                          initImages={initValues?.images}
                        />
                      </Box>
                      <Box my="0.5rem">
                        <FormLabel>
                          {t("case.create.long-image")}
                          <Tooltip
                            hasArrow
                            label={t("case.create.long-image-tip")}
                            bg="gray.300"
                            color="black"
                            px="2px"
                            display={{ base: "none", md: "block" }}
                          >
                            <Tag mx="4px">
                              <FiInfo />
                            </Tag>
                          </Tooltip>
                          :
                        </FormLabel>
                        <UploadImage
                          size="100px"
                          rounded="md"
                          onUpdateFile={handleUploadLongImage}
                          onDeleteImage={handledDeleteLongImages}
                          initImages={
                            initValues?.longImage ? [initValues?.longImage] : []
                          }
                        />
                      </Box>
                      <Field type="hidden" name="caseStatus" />
                    </Box>
                    <Box
                      w={{ base: "0%", md: initValues?.id ? "0%" : "40%" }}
                      display={{ base: "none", md: "flex" }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {!initValues?.id && <Step3></Step3>}
                    </Box>
                  </Box>
                </Box>
              )}

              <Box
                display={{ md: "flex" }}
                alignItems="center"
                justifyContent="flex-end"
                pt="10"
                w="100%"
              >
                <Button
                  mr="4"
                  mb={{ base: "1rem", md: 0 }}
                  onClick={goToPrevious}
                  isDisabled={activeStep === 0}
                >
                  {t("common.btn.previous")}
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={{ md: "flex-end" }}
                  >
                    <Button
                      leftIcon={<FiFileText />}
                      minW="8rem"
                      mr="1rem"
                      colorScheme="teal"
                      isLoading={props.isSubmitting}
                      onClick={() => {
                        props.setFieldValue("caseStatus", "draft");
                        props.submitForm();
                      }}
                    >
                      {t("common.btn.save-as-draft")}
                    </Button>
                    <Button
                      leftIcon={<FiCheck />}
                      minW="8rem"
                      colorScheme="teal"
                      isLoading={props.isSubmitting}
                      type="submit"
                      onClick={() => {
                        props.setFieldValue("caseStatus", "publish", false);
                        props.submitForm();
                      }}
                    >
                      {t("common.btn.publish")}
                    </Button>
                  </Box>
                ) : (
                  <Button
                    mb={{ base: "1rem", md: 0 }}
                    onClick={goToNext}
                    isDisabled={activeStep === 0 && isEmpty(selectedTags)}
                  >
                    {t("common.btn.next")}
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default CreateCase;
