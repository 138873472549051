import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Heading,
  Flex,
  Link,
  useColorModeValue,
  Button,
  Grid,
  GridItem,
  AspectRatio,
  Tooltip,
} from "@chakra-ui/react";
import { WarningIcon } from '@chakra-ui/icons'

// import { LuInfo } from "react-icons/lu";
// import { ToggleTip } from "../../components/ui/toggle-tip"
import { getDigitalWorkforceList } from "../../services/digitalWorkforceService";
import { useTranslation } from "react-i18next";
import { messageList } from "../../services/messageService";
import { getRequirementsList } from "../../services/requirementService";
import { getCaseList } from "../../services/caseService";
import { isEmpty } from "lodash";
import { parseCaseList } from "../../utils/case";
import { Case } from "../../models/case";
import { Broadcast } from "../../models/dashboard";
import CaseCard from "../CaseManagement/Card";
import "../../assets/css/custom.css";
import { Blog } from "../../models/blog";
import { getBlogDetail } from "../../services/blogService";
import BlogCard from "../Blogs/Card";
const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  // const [msgList, setMsgList] = useState<string[]>([]);
  // const [requirementList, setRequirementList] = useState<Requirement[]>([]);
  // const [caseList, setCaseList] = useState<Case[]>([]);
  // const [list, setList] = useState<DigitalWorkforceRow[]>([]);
  const responsiveSpacing = { base: "1rem", md: "2rem" };
  const [industryKey, setIndustryKey] = useState<string>('insurance-industry');
  const [selectedIndustry, setSelectedIndustry] = useState<string>('insurance-industry');
  useEffect(() => {
    // 监听自定义事件（当前标签页修改时触发）
    const handleLanguageChange = () => {
      setSelectedIndustry('insurance-industry');
      const industry_detail = t(`home.label.${selectedIndustry}-detail`); // 使用模板字符串
      setHoveredIndustry(industry_detail.split('@@'));
      
    };

    window.addEventListener('languageChange', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChange', handleLanguageChange);
    };
  }, []);

  const [blogValues, setBlogValues] = useState<Blog>();
  const [blogId, setBlogId] =  useState<string>();
  

  const [loading, setLoading] = useState(false); // 新增状态用于加载状态

  const handleBoxClick = async (blog_id: string) => {
    setLoading(true); // 开始加载
    setBlogId(blog_id);
    try {
      getBlogDetail(blog_id).then((res) => {
        setBlogValues(res);
      });
     
    } catch (error) {
      console.error('Error fetching blog:', error);
    } finally {
      setLoading(false); // 结束加载
    }
  };


  const insurance_industry_detail = t("home.label.insurance-industry-detail");
  const insurance_industry_detail_array = insurance_industry_detail.split('@@');
  const [hoveredIndustry, setHoveredIndustry] = useState<string[] | null>(insurance_industry_detail_array);

  
  useEffect(() => {
    getDigitalWorkforceList().then((res) => {
      // setList(res.slice(0, 4));
    });
    messageList().then((res) => {
      initMsgData(res);
    });
    getRequirementsList({ title: "" }).then((res) => {
      // setRequirementList(res.slice(0, 4));
    });
    // getCaseList("")
    //   .then((res) => {
    //     if (!isEmpty(res)) {
    //       setCaseList(parseCaseList(res.slice(0, 4)));
    //     }
    //   })
    //   .catch();
  }, []);

  const initMsgData = (data: Broadcast) => {
    const msgData = data?.messages?.map(
      (item) => `${item.userName}: ${item.messageInfo}`
    );
    if (!!msgData && msgData.length > 0) {
      msgData.unshift("[Linkedin]");
    }

    const requirementData = data?.requirements?.map(
      (item) => `${item.publisherOrganizationName}: ${item.title}`
    );

    if (!!requirementData && requirementData.length > 0) {
      requirementData.unshift("[Project Marketplace]");
    }

    const rpaTaskData = data?.rpaTasks?.map(
      (item) => `${item.organizationName}: ${item.digitalWorkforceName}`
    );

    if (!!rpaTaskData && rpaTaskData.length > 0) {
      rpaTaskData.unshift("[RPA Task]");
    }
    // const allData = msgData?.concat(requirementData).concat(rpaTaskData);

    // setMsgList(allData);
  };

  return (
    <Box className="index-container">
      <Box
       className="first-lab"
      >
        <Box display="flex" minH={{ base: "300px", md: "500px" }}>
          <Box
            minH={{ base: "300px", md: "500px" }}
            w={{ base: "100%", md: "100%" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
          <Heading as="h2" size="2xl" color="white" p="1rem" className="main-title" sx={{ fontFamily: 'Geoform-Bold' }}>
            {(() => {
              const title1 = t("home.label.title1");
              const title2 = t("home.label.title2");
              const title3 = t("home.label.title3");
              const title4 = t("home.label.title4");
              // if (/[\u4e00-\u9fa5]/.test(title1)) {
                // 中文版：将最后两个字标红
                return (
                  <>
                    <Text as="span" color="#70A288">
                    {title1}
                    </Text>
                    <Text as="span" color="#F4A261">
                    {title2}
                    </Text>
                    <Text as="span" color="#D38F38">
                    {title3}
                    </Text>
                    <Text as="span" color="#99C1D2">
                    {title4}
                    </Text>
                  </>
                );
              // } 
            })()}
          </Heading>
            <Text color="white" fontSize="2xl" p="1rem" className={`sub-title sub-title-${localStorage.getItem('lang')}`}>
              {t("home.label.sub-title")}
            </Text>
            <Box className="arrow-down"></Box>
            {/* <Button
              mt="1rem"
              colorScheme="teal"
              size="lg"
              borderRadius="full"
              onClick={() => console.log("探索数字员工")}
            >
               {t("home.label.explore-digital-employee")}
            </Button> */}
          </Box>
          {/* <Box
            w={{ base: "0%", md: "20%" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              w="80%"
              src={TurnKeyServiceImg}
              borderRadius={responsiveSpacing}
              overflow="hidden"
            ></Image>
          </Box> */}
        </Box>
        {/* <Box maxW="1240px" margin="0 auto">
          <Marquee msgs={msgList}></Marquee>
        </Box> */}
      </Box>
      <Box className="second-lab">
      {/* 隐藏原本数字员工展示部分 */}
      {/* <Box bg={useColorModeValue("gray.100", "gray.900")} px="1rem">
          <Box maxW="1240px" margin="0 auto" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box py={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="purple"
                  textAlign="center"
                  w="full"
                >
                  {t("menu.digital-workforce")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.digital-workforces")}
                </Text>
              </Box>

              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {list.map((row, index) => {
                  return (
                    <Box
                      key={index}
                      bg={"#fff"}
                      borderRadius="5px"
                      _hover={{
                        boxShadow: "lg",
                        rounded: "md",
                        cursor: "pointer",
                      }}
                    >
                      <Box w="100%" bg="gray.200" pos="relative" pb={4}>
                        <Flex justify="flex-end" fontSize="0.8rem">
                          <Text pr={2} pt={1}>
                            {t(_renderDigitalWorkforceType(row.type))}
                          </Text>
                        </Flex>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Avatar
                            size="xl"
                            name={row.name}
                            bg="teal.500"
                            color="white"
                          />
                        </Box>
                      </Box>
                      <Box px="0.8rem" pb="0.8rem">
                        <Heading
                          textAlign="center"
                          fontSize={"large"}
                          mt={2}
                          mb="0.5rem"
                          h="2.4em"
                          noOfLines={2}
                        >
                          {row.name}
                        </Heading>
                        
                        <HStack justify="center">
                          <Flex
                            flexDirection="column"
                            alignItems="center"
                            w="50%"
                          >
                            <Text
                              fontSize="2xl"
                              fontWeight="bold"
                              color="blue.500"
                            >
                              {row.executionCount}
                            </Text>
                            <Text fontSize="12px" color="gray">
                              {t("digital-workforce.list.number-of-executions")}
                            </Text>
                          </Flex>
                          <Flex
                            flexDirection="column"
                            alignItems="center"
                            w="50%"
                          >
                            <Text
                              fontSize="2xl"
                              fontWeight="bold"
                              color="blue.500"
                            >
                              {row.totalExecutionTime}h
                            </Text>
                            <Text fontSize="12px" color="gray">
                              {t("digital-workforce.list.total-execution-time")}
                            </Text>
                          </Flex>
                        </HStack>

                        <Text mt={2} textAlign="center">
                          {t("digital-workforce.list.subscribed-org")}
                        </Text>

                        <Stack
                          direction="row"
                          spacing={-10}
                          justify="center"
                          mt={1}
                        >
                          {isEmpty(row?.subscribedOrganizations) ? (
                            <Avatar size="md" />
                          ) : (
                            <>
                              {row?.subscribedOrganizations
                                ?.slice(0, 5)
                                .map((organization) => {
                                  return (
                                    <Avatar size="md" name={organization} />
                                  );
                                })}
                            </>
                          )}
                        </Stack>
                      </Box>
                    </Box>
                  );
                })}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/digital-workforce">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box> */}
        {/* 隐藏项目市场展示部分 */}
        {/* <Box bg="#fff" px="1rem">
          <Box maxW="1240px" margin="0 auto" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box py={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="cyan.400"
                  textAlign="center"
                >
                  {t("menu.project-marketplace")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.project-marketplace")}
                </Text>
              </Box>
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {requirementList.map((item, index) => (
                  <RequirementCard
                    key={index}
                    requirementInfo={item}
                    isEdit={false}
                    onRefresh={refreshData}
                  ></RequirementCard>
                ))}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/project-marketplace">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box> */}
        {/* <Box bg={useColorModeValue("gray.100", "gray.900")} px="1rem">
          <Box maxW="1240px" margin="0 auto" pt="1rem" pb="2rem">
            <Box borderRadius={responsiveSpacing}>
              <Box mb={responsiveSpacing}>
                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  color="teal"
                  textAlign="center"
                >
                  {t("case.list.case")}
                </Text>
                <Text fontSize={{ base: "xl", md: "xl" }} textAlign="center">
                  {t("dashboard.sub-title.case")}
                </Text>
              </Box>
              <SimpleGrid columns={{ base: 1, md: 4 }} spacing={5}>
                {caseList.map((item, index) => {
                  return <CaseCard key={index} caseInfo={item} />;
                })}
              </SimpleGrid>
            </Box>
            <Flex pt="2rem" justifyContent="center">
              <Link href="/articles/cases">
                <Button colorScheme="teal" variant="outline" minW="200px">
                  {t("common.btn.more")}
                </Button>
              </Link>
            </Flex>
          </Box>
        </Box> */}
        <Box >
        {(() => {
              const intro1 = t("home.label.digital-employees-intro1");
              const intro2 = t("home.label.digital-employees-intro2");
              const digital_employees_title = t("home.label.digital-employees");
              const digital_employees_question = t("home.label.digital-employee-question");
              const insurance_industry = t("home.label.insurance-industry");
              const insurance_industry_blog_id = t("home.label.insurance-industry-blog-id");
              // const insurance_industry_detail = t("home.label.insurance-industry-detail");
              // const insurance_industry_detail_array = insurance_industry_detail.split('@@');

              const marketing_services_industry = t("home.label.marketing-services-industry");
              const marketing_services_industry_detail = t("home.label.marketing-services-industry-detail");
              const marketing_services_industry_detail_array = marketing_services_industry_detail.split('@@');

              const financial_services_industry = t("home.label.financial-services-industry");
              const financial_services_industry_detail = t("home.label.financial-services-industry-detail");
              const financial_services_industry_detail_array = financial_services_industry_detail.split('@@');

              const e_commerce_industry = t("home.label.e-commerce-industry");
              const e_commerce_industry_detail = t("home.label.e-commerce-industry-detail");
              const e_commerce_industry_array = e_commerce_industry_detail.split('@@');

              const manufacturing_industry = t("home.label.manufacturing-industry");
              const manufacturing_industry_detail = t("home.label.manufacturing-industry-detail");
              const manufacturing_industry_array = manufacturing_industry_detail.split('@@');

              // if (/[\u4e00-\u9fa5]/.test(title1)) {
                // 中文版：将最后两个字标红
                return (
                  <>
                    <Box className="digital-intro">
                      <Text className="digital-intro-title" sx={{ fontFamily: "Geoform-Bold" }}>{digital_employees_title}</Text>
                      <Text className="digital-intro-subtitle" sx={{ marginTop: '20px' }}>{intro1}</Text>
                      <Text className="digital-intro-subtitle">{intro2}</Text>
                    </Box>
                    <Grid  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="6" sx={{ marginTop: '95px' }}>
                      <GridItem>
                        <Box >
                          <Text className="sub-title-common"  sx={{ marginBottom:'20px', display:'flex', alignItems: 'flex-end' }}>
                          {digital_employees_question}<Box className="hand-icon"></Box>
                          </Text>
                          {hoveredIndustry && (
                            <Box className="hover-details industry-detail" sx={{ marginTop: '20px' }}>
                              {hoveredIndustry.map((oneIndustry, index) => {
                                // 用 || 分割 oneIndustry
                                const [detail, blogId] = oneIndustry.split('||').map(item => item.trim());
                                
                                return (
                                  <Text 
                                    key={index} 
                                    className="detail-text"
                                   
                                    
                                  >
                                    {detail} <Box className="pointer-arrow"  onClick={() => handleBoxClick(blogId)}>{t('case.guide.step1.detail')}</Box>
                                  </Text>
                                );
                              })}
                            </Box>
                          )}

                        </Box>

                      
                      </GridItem>
                      <GridItem>
                              
                        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap="6" className="industry-detail-wrap">
                          {[
                            { key: "insurance-industry", name: insurance_industry, details: insurance_industry_detail_array },
                            { key: "marketing-services-industry", name: marketing_services_industry, details: marketing_services_industry_detail_array },
                            { key: "financial-services-industry", name: financial_services_industry, details: financial_services_industry_detail_array },
                            { key: "e-commerce-industry", name: e_commerce_industry, details: e_commerce_industry_array },
                            { key: "manufacturing-industry", name: manufacturing_industry, details: manufacturing_industry_array },
                          ].map((industry, index) => (
                            <GridItem colSpan={1} key={index}   onClick={() => {
                              setIndustryKey(industry.key);
                              setHoveredIndustry(industry.details);
                              setSelectedIndustry(industry.key); // 记录当前选中的行业
                            }} className={`item ${industry.key.toLowerCase().replace(/\s+/g, '-')} ${
                              selectedIndustry === industry.key ? 'selected' : ''
                            }`} >
                              <Box
                              className="card-inner"
                              sx={{ cursor: 'pointer' }}
                               
                                // onMouseLeave={() => setHoveredIndustry(insurance_industry_detail_array)}
                              >
                                <Box className="front">
                                <Box className="industry-title card-title">{industry.name}</Box>
                                </Box>
                                {industry.details && (
                                  <Box className="industry-detail back " sx={{ marginTop: '20px' }}>
                                    {industry.details.map((oneIndustry, index) => {
                                      // 用 || 分割 oneIndustry
                                      const [detail, blogId] = oneIndustry.split('||').map(item => item.trim());
                                      return (
                                        <Text 
                                          key={index} 
                                          className="detail-text"
                                          onClick={() => handleBoxClick(blogId)} // 使用分割得到的 blogId
                                        >
                                          {detail} <Box className="pointer-arrow">{t('case.guide.step1.detail')}</Box>
                                        </Text>
                                      );
                                    })}
                                  </Box>
                                )}
                              </Box>
                            </GridItem>
                          ))}
                        </Grid>
         
                      </GridItem>
                    </Grid>
                    {/* 弹出窗口部分 */}
                    {blogId && (
                      <Box
                        position="fixed"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        bg="rgba(0, 0, 0, 0.5)"
                        zIndex="1000"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Box
                          bg="white"
                          p={4}
                          borderRadius="md"
                          boxShadow="lg"
                          maxWidth={{ base: "500px", md: "900px" }} 
                          width="90%"
                          position="relative"
                          overflow="hidden" // 确保内容不会超出边界
                        >
                          <Box
                            position="absolute"
                            top="10px"
                            right="10px"
                            onClick={() => setBlogId('')}
                            className="close-btn"
                          >
                            
                          </Box>
                          <Box
                            maxHeight="80vh" // 设置最大高度
                            overflowY="auto" // 允许垂直滚动
                            p={2} // 添加内边距
                          >
                            {loading ? (
                              <Text>{t('common.loading')}...</Text>
                            ) : (
                              !!blogValues && (
                                <BlogCard isPreview={false} isPop={true} initValues={blogValues} />
                              )
                            )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </>
                );
              // } 
            })()}
          
        </Box>
      </Box>

      <Box className="four-lab">
        <Box className="first-container"  sx={{ marginTop: "80px" }}>
          <Grid  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="6" sx={{ margin: "20px ",width: "100%" }}>
            <GridItem justifyContent="center" display="flex" flexDirection='column'>
              <Heading as="h3" size="l" p="1rem"sx={{ fontFamily: 'Geoform-Bold' }}>
              {t('home.label.start-step1')}
              </Heading>
              <Text>{t('home.label.start-step1-info')} </Text>
            </GridItem>
            <GridItem marginRight="20px"> 
              <Box maxW="640px" mx="auto" my={4} sx={{ padding: '5px' }}>
                <AspectRatio ratio={16 / 9}>
                  <video
                    title="Video Title"
                    controls
                    style={{ borderRadius: '8px' }} // 可选：添加圆角样式
                  >
                   <source src="/videos/vv.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              </Box>
            </GridItem>
          </Grid>
        </Box>

        <Box className="first-container">
          <Grid  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="6" sx={{ marginTop: '20px', marginBottom: '20px',width: "100%" }}>
           
            <GridItem marginLeft="20px">
              <Box maxW="640px" mx="auto" my={4} sx={{ padding: '5px' }}>
                <AspectRatio ratio={16 / 9}>
                  <video
                    title="Video Title"
                    controls
                    style={{ borderRadius: '8px' }} // 可选：添加圆角样式
                  >
                   <source src="/videos/vv.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              </Box>
            </GridItem>
            <GridItem className=" sub-container-right">
              <Heading as="h3" size="l" p="1rem"sx={{ fontFamily: 'Geoform-Bold' }}>
              {t('home.label.start-step2')}
              </Heading>
              <Text>{t('home.label.start-step2-info')}</Text>
            </GridItem>
          </Grid>
        </Box>

        {/* <Box className="second-container" marginTop="0">
          <Grid  templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="6" sx={{ marginTop: '20px',width: "100%" }}>
           
          <GridItem className="sub-container">
              <Box maxW="640px" mx="auto" my={4} sx={{ padding: '20px 0' }}>
                <AspectRatio ratio={16 / 9}>
                  <video
                    title="Video Title"
                    controls
                    style={{ borderRadius: '8px' }} // 可选：添加圆角样式
                  >
                    <source src="/videos/vv.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </AspectRatio>
              </Box>
            </GridItem>
            <GridItem className="sub-container sub-container-right">
              <Heading as="h3" size="l" p="1rem"sx={{ fontFamily: 'Geoform-Bold' }}>
              {t('home.label.start-step2')}
              </Heading>
              <Text>{t('home.label.start-step2-info')}</Text>
            </GridItem>
          </Grid>
        </Box> */}
      </Box>
      
      <Box className="third-lab" sx={{ marginTop: "100px" }}>
      {(() => {
              const how_start = t("home.label.how-to-get-started");
              const start_intro1 = t("home.label.start-intro1");
              const start_intro2 = t("home.label.start-intro2");
              const basic_plan_title = t("home-plan.basic.title");
              const basic_price = t('home-plan.basic.price');
              const basic_plan_sub_title = t("home-plan.basic.sub-title");
              const basic_plan_third_title = t("home-plan.basic.third-title");
              const basic_plan_detail = t("home-plan.basic.detail");
              const basic_plan_detail_array = basic_plan_detail.split('@@');
              const basic_plan_fee_detail = t("home-plan.basic.fee-detail");
              const basic_plan_fee_detail_array = basic_plan_fee_detail.split('@@');

              const pro_plan_title = t("home-plan.pro.title");
              const pro_plan_sub_title = t("home-plan.pro.sub-title");
              const pro_price = t('home-plan.pro.price');
             
              const pro_plan_third_title = t("home-plan.pro.third-title");
              const pro_plan_detail = t("home-plan.pro.detail");
              const pro_plan_detail_array = pro_plan_detail.split('@@');

              const pro_plan_fee_detail = t("home-plan.pro.fee-detail");
              const pro_plan_fee_detail_array = pro_plan_fee_detail.split('@@');


              const contact_us = t("home-plan.basic.contact-us");
              const start_free_trial = t("home-plan.pro.start-free-trial");
              const include_title = t("home-plan.common.include-title");
              const benefits_title = t("home-plan.common.benefits-title");
              const fee_title = t("home-plan.common.fee-title");
              const price_per = t('home-plan.common.price-per');
              const price_unit = t('home-plan.common.price-unit');
              // if (/[\u4e00-\u9fa5]/.test(title1)) {
                // 中文版：将最后两个字标红
                return (
                  <>
                    <Box className="digital-intro">
                      <Text className="digital-intro-title" sx={{ fontFamily: "Geoform-Bold" }}>{how_start}</Text>
                      <Text className="digital-intro-subtitle" sx={{ marginTop: '20px' }}>{start_intro1}</Text>
                      <Text className="digital-intro-subtitle">{start_intro2}</Text>
                    </Box>

                    <div className="container">
                      <div className="basic">
                        <h2>{basic_plan_title}</h2>
                        {/* <div className="price-div">
                        <h2 className="plan-price">{basic_price}</h2>
                        <div className="price-unit-div">
                        <p>{price_unit}</p>
                        <p>{price_per}</p></div>
                        </div> */}
                        {/* <h4 className="info-div">{basic_plan_sub_title}</h4> */}
                        <h6 className="info-div">{basic_plan_third_title}</h6>
                        <h3>{include_title}</h3>
                        <ul className="basic-ul">
                        {basic_plan_detail_array.map((dd, index) => (
                            <li className="basic-li" key={index}>{dd}</li>
                        ))}
                        </ul>
                        {/* <h3>{benefits_title}</h3>
                        <ul className="basic-ul">
                            <li className="benefit-li">{t('home-plan.basic.benefits-detail')}</li>
                        </ul> */}

                        <h3>{fee_title}</h3>
                        <ul className="basic-ul">
                        {basic_plan_fee_detail_array.map((dd, index) => (
                            <li className="fee-li" key={index} >
                              <Box sx={{ display: "flex" }}>
                              {dd}
                              {index === basic_plan_fee_detail_array.length - 1 && (
                               
                               <Tooltip label={t('home-plan.basic.benefits-detail')} sx={{ color: "#EDF2F7" }}>
                                <WarningIcon w={3} h={3} color="#edf2f7.500" />
                               </Tooltip>
                               )}
                               </Box>
                              </li>
                        ))}
                        </ul>
                        {/* <button className="basic-btn">{contact_us}</button> */}
                      </div>
                      <div className="pro">
                        <h2>{pro_plan_title}</h2>
                        {/* <div className="price-div">
                        <h2 className="plan-price">{pro_price}</h2>
                        <div className="price-unit-div">
                        <p>{price_unit}</p>
                        <p>{price_per}</p></div>
                        </div> */}
                        {/* <h4 className="info-div">{pro_plan_sub_title}</h4> */}
                        <h6 className="info-div">{pro_plan_third_title}</h6>
                        <div className="include-div">
                        <h3>{include_title}</h3>
                        <ul className="pro-ul">
                        {pro_plan_detail_array.map((dd, index) => (
                            <li className="pro-li" key={index}>
                                {dd}
                                
                                
                            </li>
                        ))}
                        </ul>
                        
                        <h3>{fee_title}</h3>
                        <ul className="basic-ul">
                        {pro_plan_fee_detail_array.map((dd, index) => (
                            <li className="fee-li" key={index}>{dd}</li>
                        ))}
                        </ul>
                        </div>
                        {/* <button className="pro-btn">{start_free_trial}</button> */}
                      </div>
                    </div>
                  </>
                );
              // } 
            })()}
          
      </Box>
    </Box>
  );
};

export default Dashboard;
