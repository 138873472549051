import { Box, Button, Center, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { organizationDetail } from "../../../services/organizationService";
import Empty from "../../../components/Empty";
import { OrganizationInfo } from "../../../models/auth";

const OrganizationDetails: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [organizationInfo, setOrganizationInfo] = useState<OrganizationInfo>();
  const params = useParams();
  const id = params.id;
  useEffect(() => {
    if (!!id) {
      organizationDetail(id).then((res) => {
        setOrganizationInfo(res);
      });
    }
  }, [id]);

  return (
    <Box maxW="1240px" margin="0 auto">
      <Button
        leftIcon={<FiChevronLeft />}
        colorScheme="teal"
        variant="outline"
        size="md"
        mb="1rem"
        onClick={() => navigate(`/organizations`)}
      >
        {t("common.btn.back")}
      </Button>
      {!!organizationInfo?.name ? (
        <Box p="1rem" pb="3rem" bgColor="#fff">
          <Center>
            <Heading as="h5" size="lg" py="2rem" fontWeight="bold">
              {organizationInfo?.name}
            </Heading>
          </Center>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("organization.list.number")}
            </Heading>
            <Box width="100%" pt="6px" pl="6px" fontWeight="bold">
              {organizationInfo?.number}
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            pt="0.5rem"
            flexDirection="column"
          >
            <Heading as="h5" size="sm" color="gray.400">
              {t("organization.list.description")}
            </Heading>
            <Box
              width="100%"
              pt="6px"
              pl="6px"
              minHeight="30px"
              fontWeight="bold"
            >
              {organizationInfo?.description}
            </Box>
          </Box>
        </Box>
      ) : (
        <Empty></Empty>
      )}
    </Box>
  );
};

export default OrganizationDetails;
