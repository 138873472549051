import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useState } from "react";
import InvoiceList from "./List";

const WorkbenchJobLogsManagement = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    { label: "menu.job-logs-management", path: "/workbench/log" },
  ];
  const [refresh, setRefresh] = useState(0);

  return (
    <Box margin="0 auto" mb="1rem" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Box bg={"#fff"} p="1rem" pb="1rem" mb="1rem">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {t("menu.job-logs-management")}
        </Text>    
      </Box>
      
      <InvoiceList refresh={refresh}></InvoiceList>

    </Box>
  );
};

export default WorkbenchJobLogsManagement;
