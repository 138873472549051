import { useEffect, useState } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { FiPlus } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { isPlatformAdmin } from "../../../../utils/auth";
import {
  ReleaseDIgitalWorkforceClientItem,
} from "../../../../models/releaseDigitalWorkforce";
import EditReleaseDigitalWorkforceClient from "./Edit";
import { getReleaseDigitalWorkforceClientList } from "../../../../services/releaseDigitalWorkforce";

const ReleaseDigitalWorkforceClient = () => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const _initValue = {
    clientName: '',
    clientDescription: '',
    vhost: '',
    queueName: '',
    logVhost: '',
    logQueueName: '',
    timeZone: '',  
  };
  const [tableList, setTableList] = useState<ReleaseDIgitalWorkforceClientItem[]>([]);
  const [initValue, setInitValue] =
    useState<ReleaseDIgitalWorkforceClientItem>(_initValue);

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.release-digital-workforce",
      path: "/workbench/release-digital-workforce",
    },
    {
      label: "release-digital-workforce.items.client",
      path: "/workbench/release-digital-workforce/client",
    },
  ];
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const tableFiled = [
    t("release-digital-workforce.client.name"),
    t("release-digital-workforce.client.description"),
    t("release-digital-workforce.client.vhost"),
    t("release-digital-workforce.client.queueName"),
    t("release-digital-workforce.client.logVhost"),
    t("release-digital-workforce.client.logQueueName"),
    t("release-digital-workforce.client.timezone"),
    t("common.btn.actions"),
  ];

  useEffect(() => {
    fetchList({ clientName: "" });
  }, []);

  const fetchList = (data: { clientName: string }) => {
    getReleaseDigitalWorkforceClientList(data).then((res) => {
      setTableList(res);
    });
  };

  const onRefresh = () => {
    fetchList({ clientName: "" });
  };

  const _renderCell = (
    row: ReleaseDIgitalWorkforceClientItem,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any,
    limitLine?: number
  ) => {
    return (
      <Td py="0.5rem" {...extraProps} pl="0.2rem">
        <Text
          fontSize={fontSize}
          fontWeight={fontWeight}
          noOfLines={limitLine ? limitLine : undefined}
        >
          {text}
        </Text>
      </Td>
    );
  };

  const _renderTableRow = (row: ReleaseDIgitalWorkforceClientItem, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.clientName, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.clientDescription, "sm", "bold", { minW: "200px" })}
        {_renderCell(row, row.vhost, "sm", "normal", {}, 1)}
        {_renderCell(row, row.queueName, "sm", "normal", {}, 1)}
        {_renderCell(row, row.logVhost, "sm", "normal", {}, 1)}
        {_renderCell(row, row.logQueueName, "sm", "normal", {}, 1)}
        {_renderCell(row, row.timeZone, "sm", "normal", {}, 1)}
      
        <Td pl="0.2rem">
          <Flex>
            <Button
              size="sm"
              colorScheme="teal"
              mr="0.5rem"
              onClick={() => {
                setInitValue(row);
                onCreateModalOpen();
              }}
            >
              {t("common.btn.edit")}
            </Button>
          </Flex>
        </Td>
      </Tr>
    );
  };

  return (
    <Box maxW="1240px" margin="0 auto" px="1rem">
      <Box py="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>

      <Box mb="1rem" p="1rem" bgColor="#fff">
        <Button
          leftIcon={<FiPlus />}
          colorScheme="teal"
          variant="solid"
          isDisabled={!isPlatformAdmin()}
          size="md"
          onClick={() => {
            setInitValue(_initValue);
            onCreateModalOpen();
          }}
        >
          {t("release-digital-workforce.client.create")}
        </Button>
      </Box>
      {/* <Box mb="1rem" p="1rem" bgColor="#fff">
        <Formik
          initialValues={{ clientName: "" }}
          validationSchema={
            releaseDigitalWorkforceClientSearchValidationSchema
          }
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <Form>
              <Field name="clientName">
                {({ field, form }: FieldProps<{ clientName: string }>) => (
                  <FormControl
                    isInvalid={
                      !!(form.errors.clientName && form.touched.clientName)
                    }
                  >
                    <Box display="flex" alignItems="center">
                      <Input
                        {...field}
                        size="md"
                        maxW={{ base: "12rem", md: "15rem" }}
                        placeholder={t(
                          "release-digital-workforce.client.placeholder"
                        )}
                        value={field.value.clientName}
                      />
                      <Button
                        colorScheme="teal"
                        type="submit"
                        size="sm"
                        ml="1rem"
                        isLoading={formikProps.isSubmitting}
                      >
                        {t("common.btn.search")}
                      </Button>
                    </Box>
                    <FormErrorMessage>
                      {form.errors.title as string}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Form>
          )}
        </Formik>
      </Box> */}
      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {!!tableList.length && tableList.map((row: ReleaseDIgitalWorkforceClientItem,index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
      <EditReleaseDigitalWorkforceClient
        isOpen={isCreateModalOpen}
        onOpen={onCreateModalOpen}
        onClose={onCreateModalClose}
        initialValues={initValue}
        onSubmit={onRefresh}
      />
    </Box>
  );
};

export default ReleaseDigitalWorkforceClient;
