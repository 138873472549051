import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Card,
  CardBody,
  HStack,
  Text,
  useToast,
  CardFooter,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FileUpload, FileUploadDropzone, FileUploadTrigger } from "@saas-ui/file-upload";
import { uploadInvoiceDetails } from "../../../../../services/dispatchTasksService";

interface PreviewFormProps {
  isOpen: boolean;
  setInvoice: (data: any[]) => void;
  onOpen: () => void;
  onClose: () => void;
}

const ImportInvoiceList: React.FC<PreviewFormProps> = ({
  isOpen,
  setInvoice,
  onOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<File[]>([]);
  const toast = useToast();

  const submitInvoiceFiles = async () => {
    const formdata = new FormData();
    if(files.length) {
      formdata.append("file", files[0]);
      uploadInvoiceDetails(formdata).then((res)=>{
        if(res.length) {
          toast({
            title: t('invoice.upload.message.success'),
            status: 'success',
            duration: 3000,
            position: 'top',
            isClosable: true,
          });
          const invoices = res.map(item => {
            return {
              "item_name": item.itemName,
              "item_specification": item.specification,
              "item_unit": item.unit,
              "item_quantity": item.quantity,
              "item_price_with_tax": item.unitPrice,
              "item_total_price": item.amount,
              "tax_rate": item.tax
          }
          });
          setInvoice(invoices);
        }
        
      }).catch(()=> {
        toast({
          title: t('invoice.upload.message.fail'),
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
      });
    }

    onClose();
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW={{ md: "70rem" }}>
        <ModalHeader>
          导入发票明细
        </ModalHeader>
        <ModalCloseButton />
        <Card mb="1rem">
          <CardBody overflow={'auto'}>
          <FileUpload maxFileSize={1024 * 1024} maxFiles={1} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onFileAccept={details => {setFiles(details.files)}}>
                  {({ acceptedFiles, deleteFile }) => (
                    <FileUploadDropzone>
                      <Text fontSize="sm">Drag your file here</Text>
                      {!acceptedFiles?.length ? (
                        <FileUploadTrigger as={Button}>{t('invoice.upload.select')}</FileUploadTrigger>
                      ) : (
                        <HStack>
                          {acceptedFiles.map(acceptedFile => <Text fontSize="sm">{acceptedFile.name}</Text>)}
                          <Button
                            onClick={(e) => {
                              e.stopPropagation()
                              deleteFile(acceptedFiles[0])
                            }}
                          >
                            {t('common.btn.clear')}
                          </Button>
                        </HStack>
                      )}
                    </FileUploadDropzone>
                  )}
                </FileUpload>
          </CardBody>
          <CardFooter>
            <Button onClick={submitInvoiceFiles}>Submit</Button>
          </CardFooter>
        </Card>
      </ModalContent>
    </Modal>
  );
};

export default ImportInvoiceList;
