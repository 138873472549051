import React from "react";
import { isArray, merge } from "lodash";
import RemoveButton from "./RemoveButton";
import DuplicateButton from "./DuplicateButton";

export default class ParentLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovering: false,
    };
  }

  hoverStarted = () => {
    this.setState({ hovering: true });
  };

  hoverStopped = () => {
    this.setState({ hovering: false });
  };

  render() {
    let {
      marginLeft,
      value,
      currentKey,
      getCollapseIcon,
      duplicateElement,
      removeElement,
      current,
      parent,
      showRemoveButton,
      showAddButton,
      styles,
    } = this.props;
    let style = merge(
      { marginLeft: marginLeft, display: "flex" },
      styles.label
    );
    return (
      <div
        style={style}
        onMouseEnter={this.hoverStarted}
        onMouseLeave={this.hoverStopped}
      >
        <div>{value}</div>
        <div title="collapse node" style={{ marginLeft: 5 }}>
          {getCollapseIcon(marginLeft, currentKey)}
        </div>
        <div hidden={!this.state.hovering} style={{ marginLeft: 10 }}>
          {isArray(parent) && marginLeft === 10 && 
          <DuplicateButton
            hidden={!showAddButton}
            duplicateElement={duplicateElement}
            addTo={parent}
            styles={styles}
          />
          }
          {isArray(current) && 
          <DuplicateButton
            hidden={!showAddButton}
            duplicateElement={duplicateElement}
            addTo={current}
            styles={styles}
          />
          }
          {isArray(parent)  && parent.length > 1
          && <RemoveButton
              hidden={!showRemoveButton}
              removeFrom={parent}
              removeKey={currentKey}
              removeElement={removeElement}
              styles={styles}
            />
          }
          
        </div>
      </div>
    );
  }
}
