import {
  AspectRatio,
  Box,
  Text,
  useColorModeValue,
  Image,
  Button,
  Tag,
  Heading,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useToast,
  Link,
} from "@chakra-ui/react";
import {
  FiTag,
  FiUser,
  FiPlus,
  FiCheckSquare,
  FiFileText,
} from "react-icons/fi";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateCase from "./Create";
import { motion } from "framer-motion";
import { deleteCase, getCaseList } from "../../services/caseService";
import { Case } from "../../models/case";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { UserInfo } from "../../models/auth";
import { canEdit } from "../../utils/auth";
import { parseCaseList } from "../../utils/case";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";

const CaseManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [caseList, setCaseList] = useState<Case[]>([]);
  const [editCase, setEditCase] = useState<Case>({
    title: "",
    status: "Open",
  } as Case);
  const userInfo = useSelector((state: any) => state.userInfo as UserInfo);
  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.case-management", path: "/articles/cases" },
  ];
  const MotionBox = motion(Box);
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const textColor = useColorModeValue("gray.700", "white");
  useEffect(() => {
    fetchCaseList();
  }, []);

  const isPublished = (value: string) => {
    return value?.toLowerCase() === "publish";
  };

  const isOwnerCase = (organizationId: string) => {
    return organizationId === userInfo.currentOrganizationId;
  };

  const handleDeleteCase = () => {
    deleteCase(editCase.id!)
      .then((res) => {
        onConfirm();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const fetchCaseList = () => {
    getCaseList(userInfo.currentOrganizationId)
      .then((res) => {
        if (!isEmpty(res)) {
          setCaseList(parseCaseList(res));
        }
      })
      .catch();
  };

  const onConfirm = () => {
    onDeleteModalClose();
    onCreateClose();
    onEditClose();
    fetchCaseList();
  };

  const _renderCaseRow = (row: Case, index: number) => {
    return (
      <>
        <Box
          display={{ lg: "flex" }}
          p="1rem"
          mb="1rem"
          bg={isPublished(row?.caseStatus) ? "#fff" : "#eee"}
          _hover={{
            boxShadow: "lg",
            rounded: "md",
            cursor: "pointer",
          }}
          borderRadius="8px"
          key={index}
        >
          <Box w={{ base: "100%", lg: "15%", xl: "10%" }}>
            <Box w="100%" h="100%" display="flex" alignItems="center">
              <AspectRatio w="100%" ratio={1 / 1}>
                <Image src={row.images[0]?.url} objectFit="cover" />
              </AspectRatio>
            </Box>
          </Box>
          <Box
            w={{ base: "100%", lg: "85%", xl: "90%" }}
            pl={{ lg: "1rem" }}
            display={{ lg: "flex" }}
          >
            <Box
              onClick={() => navigate(`/articles/cases/details/${row.id}`)}
              w={{
                base: "100%",
                lg:
                  canEdit() && isOwnerCase(row.organizationId) ? "80%" : "100%",
              }}
            >
              <Box>
                <Heading size="md" color="teal" noOfLines={{ base: 2, xl: 1 }}>
                  {row.title}
                </Heading>
              </Box>
              <Box color="gray.700">
                <Text noOfLines={3}>{row.backgroundDesc}</Text>
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                py={{ base: "0.3rem", xl: "0.5rem" }}
                color="gray.500"
              >
                <Box display="flex" alignItems="center">
                  <FiTag fontSize="1rem" />
                  <Text color="gray.500"> {t("case.list.tags")}:</Text>
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center">
                  {row.tags!.map((row: string, index: number) => {
                    return _renderTag(row, index);
                  })}
                </Box>
              </Box>
              <Box
                display={{ lg: "flex" }}
                justifyContent="space-between"
                fontSize="0.9rem"
                color="gray.500"
              >
                {isPublished(row.status) ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    pr="0.5rem"
                    color="teal"
                    pb={{ base: "0.3rem", xl: "0" }}
                  >
                    <FiCheckSquare />
                    <Text pl="0.2rem">
                      {t("case.list." + row?.caseStatus.toLowerCase())}
                    </Text>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    pr="0.5rem"
                    pb={{ base: "0.3rem", xl: "0" }}
                  >
                    <FiFileText />
                    <Text pl="0.2rem">
                      {t("case.list." + row?.caseStatus.toLowerCase())}
                    </Text>
                  </Box>
                )}

                <Box
                  display={{ lg: "flex" }}
                  justifyContent="flex-end"
                  color="gray.500"
                  fontSize="0.9rem"
                  // w="70%"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    pr="0.5rem"
                    pb={{ base: "0.3rem", xl: "0" }}
                    borderRight={{ lg: "1px solid" }}
                  >
                    <FaRegCalendarAlt />
                    <Text pl="0.2rem">{t("case.list.posted-at")}:</Text>
                    <Text pl="0.2rem">{row.createdDate.replace("T", " ")}</Text>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    pl={{ lg: "0.5rem" }}
                    pb={{ base: "0.3rem", xl: "0" }}
                  >
                    <FiUser />
                    <Text pl="0.2rem">{t("case.list.owner")}:</Text>
                    <Link
                      href={"/organizations/details/" + row.organizationId}
                      target="_blank"
                    >
                      <Text color="teal" pl="0.2rem">
                        {row.ownedBy}
                      </Text>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
            {canEdit() && isOwnerCase(row.organizationId) ? (
              <Box
                w={{ base: "100%", lg: "20%" }}
                display="flex"
                flexDirection={{ base: "row", lg: "column" }}
                alignItems="center"
                justifyContent={{ base: "flex-end", lg: "space-evenly" }}
              >
                <Button
                  size="sm"
                  minW="70px"
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => {
                    setEditCase(row);
                    onCreateOpen();
                  }}
                >
                  {t("common.btn.edit")}
                </Button>
                <Button
                  ml={{ base: "1rem", lg: "0" }}
                  size="sm"
                  minW="70px"
                  colorScheme="red"
                  onClick={() => {
                    setEditCase(row);
                    onDeleteModalOpen();
                  }}
                >
                  {t("common.btn.delete")}
                </Button>
              </Box>
            ) : null}
          </Box>
        </Box>
        <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
          <ModalContent>
            <ModalHeader> {t("case.list.delete-modal.title")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>{t("case.list.delete-modal.content")}</ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onDeleteModalClose} size="sm">
                {t("common.btn.cancel")}
              </Button>
              <Button
                colorScheme="red"
                size="sm"
                onClick={() => handleDeleteCase()}
              >
                {t("common.btn.delete")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };

  const _renderTag = (row: string, index: number) => {
    return (
      <Tag
        size="md"
        variant="solid"
        // colorScheme="orange"
        ml="0.5rem"
        my={{ base: "0.1rem", xl: 0 }}
        key={index}
      >
        {row}
      </Tag>
    );
  };

  return (
    <>
      <Box position="relative" maxW="1240px" margin="0 auto" px="1rem">
        {!isCreateOpen && !isEditOpen && (
          <Box>
            <Box py="1rem">
              <CustomBreadcrumb routes={breadcrumbRoutes} />
            </Box>
            <Box bg={"#fff"} p="1rem" mb="1rem">
              <Box pb={canEdit() ? "1rem" : 0}>
                <Text fontSize="xl" color={textColor} fontWeight="bold">
                  {t("case.list.header")}
                </Text>
              </Box>
              {canEdit() ? (
                <Box>
                  <Button
                    leftIcon={<FiPlus />}
                    colorScheme="teal"
                    variant="solid"
                    size="md"
                    onClick={() => {
                      setEditCase({
                        title: "",
                        status: "Open",
                      } as Case);
                      onCreateOpen();
                    }}
                  >
                    {t("case.list.create")}
                  </Button>
                </Box>
              ) : null}
            </Box>
            {isAvailable && (
              <Box>
                {caseList?.map((row, index) => {
                  return _renderCaseRow(row, index);
                })}
              </Box>
            )}
          </Box>
        )}
        {!isAvailable && (
          <Box bg={"#fff"} p="1rem" mb="1rem" textAlign="center">
            <Text color={"gray.500"} mb={6}>
              {t("case.list.notice.title")}
            </Text>
            <Button
              colorScheme="teal"
              bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
              color="white"
              variant="solid"
            >
              <Link
                as={RouterLink}
                to={"/workbench/organization"}
                display="flex"
                alignItems="center"
                style={{ textDecoration: "none" }}
              >
                {t("case.list.notice.btn")}
              </Link>
            </Button>
          </Box>
        )}

        {isCreateOpen && (
          <MotionBox
            initial={{ x: !isEditOpen ? "100%" : 0 }}
            animate={{ x: !isEditOpen ? 0 : "100%" }}
            transition={{ duration: 0.5 }}
            // position="absolute"
            // top="0"
            // left="0"
            width="100%"
            // height="100%"
            // minH="1000px"
          >
            <CreateCase
              isOpen={isCreateOpen}
              onOpen={onCreateOpen}
              onClose={onCreateClose}
              onConfirm={onConfirm}
              initValues={editCase}
            ></CreateCase>
          </MotionBox>
        )}
      </Box>
    </>
  );
};

export default CaseManagement;
