import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Heading,
  Text,
  Center,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../../../../components/CustomBreadcrumb";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import {
  singleSubscribedDigitalWorkforce,
} from "../../../../services/releaseDigitalWorkforce";
import { SubscribedDigitalWorkforceInfo } from "../../../../models/releaseDigitalWorkforce";
import {
  getStatusIcon,
  getStatusText,
} from "../../../../services/digitalWorkforceService";

const CustomerDigitalWorkforceTasks: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<SubscribedDigitalWorkforceInfo | null>(null);
  const [expandedIndex, setExpandedIndex] = useState<string | null>(null);

  const breadcrumbRoutes = [
    { label: "menu.home", path: "/" },
    { label: "menu.workbench", path: "/workbench" },
    {
      label: "menu.digital-workforce",
      path: "/workbench/digital-workforce",
    },
    {
      label: "menu.digital-workforce-function",
      path: "/workbench/digital-workforce/tasks",
    },
  ];

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const fetchData = () => {
    singleSubscribedDigitalWorkforce(id!)
      .then((res) => {
        setData(res);
      })
      .catch();
  };
  const toggleDesc = (index: string) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box maxW="1240px" margin="0 auto" p="1rem">
      <Box pb="1rem">
        <CustomBreadcrumb routes={breadcrumbRoutes} />
      </Box>
      <Center>
        <Heading>{t("menu.digital-workforce-function")}</Heading>
      </Center>
      {!!data && !!data.digitalWorkforces && (
        <>
          {data.digitalWorkforces.map((digitalWorkforces, d_index) => {
            return (
              <Box>
                {digitalWorkforces.groups.map((group, index) => {
                  return (
                    <Box
                      bg="#fff"
                      borderRadius="md"
                      p={{ base: "0.5rem", md: "1rem" }}
                      m="1rem"
                      boxShadow="small"
                      transition="transform 0.3s"
                      _hover={{
                        boxShadow: "lg",
                        rounded: "md",
                        cursor: "pointer",
                        transform: "translateY(-6px)",
                      }}
                    >
                      <Box
                        display={{ md: "flex" }}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Flex>
                          <Heading size="md">{group.groupName}</Heading>
                          <Text pl="6px" mt="-6px">
                            {getStatusIcon(group.taskGroupStatus!)}
                          </Text>
                        </Flex>

                        <Flex
                          mt={{ base: "2", md: 0 }}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box
                            onClick={() => toggleDesc(d_index + "_" + index)}
                            fontSize="24px"
                          >
                            {expandedIndex === d_index + "_" + index ? (
                              <FiChevronDown />
                            ) : (
                              <FiChevronUp />
                            )}
                          </Box>
                        </Flex>
                      </Box>

                      {expandedIndex === d_index + "_" + index && (
                        <>
                          <Flex py="0.5rem">
                            <Text>
                              {t("release-digital-workforce.function.desc")}:
                            </Text>
                            <Text pl="0.5rem">{group.groupDescription}</Text>
                          </Flex>
                          <Flex py="0.5rem">
                            <Text>{t("common.status")}:</Text>

                            <Text pl="0.5rem">
                              {getStatusText(group.taskGroupStatus!)}
                            </Text>
                          </Flex>
                        </>
                      )}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default CustomerDigitalWorkforceTasks;
