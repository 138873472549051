import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Layout from "./components/Layout";
import Active from "./pages/Auth/Active";
import Invitation from "./pages/Auth/Invitation";
import theme from "./theme";
import { useEffect, useState, createContext, useRef } from "react";
import { guestLogin } from "./services/authService";
import { setLocalInfo } from "./utils/auth";
import { setUserInfo, setUserRoles } from "./store";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { DirectLine } from "botframework-directlinejs";
// import ReactWebChat from "botframework-webchat";
// import { Button } from "@chakra-ui/react";
// import { ChatIcon, CloseIcon } from "@chakra-ui/icons";

export const CartCountsContext = createContext(undefined as any);

export const App = () => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [cartCounts, setCartCounts] = useState(0);
  // const [showChat, setShowChat] = useState(false); //控制聊天框是否展开
  // const directLineRef = useRef<DirectLine | null>(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      guestLogin().then((res) => {
        setLocalInfo(res);
        const userInfo = res.user;
        let organizationRoles: string[] = [];
        const organizations = userInfo.organizations;
        dispatch(setUserInfo(userInfo));

        if (!isEmpty(organizations)) {
          const organization = organizations.find(
            (item: any) => item.id === userInfo.currentOrganizationId
          );
          if (!!organization) {
            organizationRoles.push(
              "Organization." + organization.organizationRole
            );
          }
        }
        dispatch(setUserRoles([...userInfo.roles, ...organizationRoles]));
        setIsLogin(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } else {
      setIsLogin(true);
    }

    // 初始化 Direct Line 连接
    // const directLine = new DirectLine({
    //   secret: "3r1Nbd3PEsAJeDMdqSPJu4lveEPQ1aPnbre6h66klts61MADfMrTJQQJ99BBACZoyfiAArohAAABAZBS4V7F.3J1Nalp57OARhmBvKey3wN2l1klluIoRJsIjByuqbemoZJGqAL3OJQQJ99BBACZoyfiAArohAAABAZBS1ynd",
    // });

    // directLineRef.current = directLine;
  }, [dispatch]);

  return (
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId="412465180438-99cconbtpim1dpcvlveq654akbagmvie.apps.googleusercontent.com">
        <CartCountsContext.Provider value={{ cartCounts, setCartCounts }}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/user/active/:id" element={<Active />} />
            <Route path="/user/invitation/:id" element={<Invitation />} />
            <Route
              path="/user/forgot-password/:email"
              element={<ForgotPassword />}
            />
            <Route path={"/register"} element={<Register />} />
            {isLogin && <Route path="/*" element={<Layout />} />}
          </Routes>

          {/* 聊天图标 & 聊天窗口 */}
          {/* <div style={{ position: "fixed", bottom: "20px", right: "20px" }}>
            <Button
              onClick={() => setShowChat(!showChat)}
              colorScheme="blue"
              borderRadius="50%"
              width="60px"
              height="60px"
              boxShadow="md"
            >
              {showChat ? <CloseIcon /> : <ChatIcon />}
            </Button>

            {showChat && directLineRef.current && (
              <div
                style={{
                  position: "absolute",
                  bottom: "80px",
                  right: "0px",
                  width: "450px",
                  height: "550px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundColor: "#fff",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ReactWebChat
                  directLine={directLineRef.current}
                  userID="user123"
                  styleOptions={{
                    hideUploadButton: true,
                    bubbleBackground: "#f0f0f0",
                    botAvatarInitials: 'Bot',
                    userAvatarInitials: 'U',
                  }}
                />
              </div>
            )}
          </div> */}
        </CartCountsContext.Provider>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
};
