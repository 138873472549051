import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { InvoiceInfoItem } from "../../../../models/invoice";
import { deleteInvoiceInfo, getInvoiceInfoList } from "../../../../services/invoiceService";
import { canEdit } from "../../../../utils/auth";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment-timezone";
import CreateInvoiceInfo from "./Create";

interface UploadProps {
  refresh: number;
}
const InvoiceInfoList: React.FC<UploadProps> = ({refresh}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const { t } = useTranslation();
  const toast = useToast();
  const [invoiceInfo, setInvoiceInfo] = useState<InvoiceInfoItem>({
    name: "",
    usci: "",
    phone: "",
    address: "",
    bankName: "",
    bankAccount: "",
    displayAddress: true,
    displayBankAccount: true,
  });
  const [tableList, setTableList] = useState<InvoiceInfoItem[]>([]);
  const [deleteId, setDeleteId] = useState<string>();


  const tableFiled = [
    t("invoice.info.fields.name"),
    t("invoice.info.fields.usci"),
    t("invoice.info.fields.address"),
    t("invoice.info.fields.phone"),
    t("invoice.info.fields.displayAddress"),
    t("invoice.info.fields.bankName"),
    t("invoice.info.fields.bankAccount"),
    t("invoice.info.fields.displayBankAccount"),
    t("common.btn.actions"),
  ];
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  useEffect(() => {
    fetchInvoiceInfoList();
  }, [refresh]);

  const fetchInvoiceInfoList = (data?: { sellerName?: string, buyerName?: string, invoiceNumber?: string, startDate?: string, endDate?: string }) => {
    getInvoiceInfoList(data).then((res) => {
      const data = res.map(item => {
        return {
          ...item,
        }
      })
      setTableList(_.orderBy(data, "createdDate", "desc"));
    });
  };

  const _renderCell = (
    row: InvoiceInfoItem,
    text: any,
    fontSize: string,
    fontWeight: string,
    extraProps?: any
  ) => {
    return (
      <Td
        py="0.5rem"
        {...extraProps}
        pl="0.2rem"
      >
        <Text fontSize={fontSize} fontWeight={fontWeight}>
          {text}
        </Text>
      </Td>
    );
  };

  const showOrHide = (value: boolean) => {
    if(value) {
      return t('invoice.info.fields.show');
    } else {
      return t('invoice.info.fields.hide');
    }
  }

  const _renderTableRow = (row: InvoiceInfoItem, index: number) => {
    return (
      <Tr
        key={index}
        _hover={{ bg: "green.200", cursor: "pointer", color: "white" }}
      >
        {_renderCell(row, row.name, "md", "bold", { minW: "200px" })}
        {_renderCell(row, row.usci, "md", "normal", { minW: "200px" })}
        {_renderCell(row, row.address, "md", "normal", { minW: "200px" })}
        {_renderCell(row, row.phone, "md", "normal")}
        {_renderCell(row, showOrHide(row.displayAddress), "md", "normal")}
        {_renderCell(row, row.bankName, "md", "normal")}
        {_renderCell(row, row.bankAccount, "md", "normal")}
        {_renderCell(row, showOrHide(row.displayBankAccount), "md", "normal")}
        <Td py="0.5rem" pl="0.2rem">
          {canEdit() && (
            <Flex>
            <Button
              size="sm"
              colorScheme="teal"
              mr="0.5rem"
              onClick={() => {
                setInvoiceInfo(row);
                onCreateModalOpen();
              }}
            >
              {t("common.btn.edit")}
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              mr="0.5rem"
              onClick={() => {
                setDeleteId(row.id);
                onDeleteModalOpen();
              }}
            >
              {t("common.btn.delete")}
            </Button>
            </Flex>
          )}
        </Td>
      </Tr>
    );
  };

  const handleSubmit = (
    values: { sellerName?: string, buyerName?: string, invoiceNumber?: string, startDate?: string, endDate?: string },
    formikHelpers: { setSubmitting: (arg0: boolean) => void }
  ) => {
    if(!!values.startDate && !!values.endDate) {
      const startDate = moment(values.startDate);
      const endDate = moment(values.endDate);
      if(startDate.diff(endDate) > 0) {
        toast({
          title: t('invoice.info.error.date-range'),
          position: "top",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        formikHelpers.setSubmitting(false);
        return;
      }
    }
    const filters = _.pickBy(values, item => !!item);
    fetchInvoiceInfoList(filters);
    formikHelpers.setSubmitting(false);
  }

  const handleDelete = () => {
    deleteInvoiceInfo(deleteId!)
      .then((res) => {
        fetchInvoiceInfoList();
        onDeleteModalClose();
        toast({
          title: t("common.btn.delete-success-msg"),
          position: "top",
          status: "success",
          isClosable: true,
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: t("common.btn.delete-error-msg"),
          position: "top",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  return (
    <>
     <Box  bgColor="#fff">
        <Button
          colorScheme="teal"
          variant="solid"
          size="md"
          onClick={() => {
              onCreateModalOpen();
          }}
        >
          {t("invoice.info.fields.create")}
        </Button>
      </Box>
    <Box mb="1rem" pt="1rem"  pb="1rem" bgColor="#fff">
      <Formik
        initialValues={{ keyword: ""}}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form dir="row">
            <Flex columnGap="1rem" alignItems={"center"}>
            <Field name="keyword">
              {({ field, form }) => (
                <FormControl
                flexBasis="200px"
                >
                  <Input
                      {...field}
                      size="md"
                      placeholder={t(
                        "invoice.info.fields.name"
                      )}
                      value={field.value.keyword}
                    />
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme="teal"
              type="submit"
              size="sm"
              ml="1rem"
              isLoading={formikProps.isSubmitting}
            >
              {t("common.btn.search")}
            </Button>
            </Flex>
            
          </Form>
        )}
      </Formik>
    </Box>
    <Box>
      <Box p="1rem" bgColor="#fff" overflowX={{ base: "scroll", md: "hidden" }}>
        <Table variant="simple" color={textColor}>
          <Thead>
            <Tr my=".8rem" pl="0px" color="gray.400">
              {tableFiled.map((caption, idx) => {
                return (
                  <Th color="gray.400" key={idx} ps={idx === 0 ? "0px" : ""}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {tableList.map((row: InvoiceInfoItem, index) => {
              return _renderTableRow(row, index);
            })}
          </Tbody>
        </Table>
      </Box>
    </Box>
    <CreateInvoiceInfo
        isOpen={isCreateModalOpen}
        onOpen={onCreateModalOpen}
        initialValues={invoiceInfo!}
        onClose={()=>{
          setInvoiceInfo({
            name: "",
            usci: "",
            phone: "",
            address: "",
            bankName: "",
            bankAccount: "",
            displayAddress: true,
            displayBankAccount: true,
          });
          onCreateModalClose();
        }}
        onSubmit={fetchInvoiceInfoList}
      />
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> {t("blogs.delete-modal.title")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {t("invoice.info.fields.msg.delete-confirm")}
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={onDeleteModalClose} size="sm">
                {t("common.btn.cancel")}
              </Button>
              <Button colorScheme="red" size="sm" onClick={() => handleDelete()}>
                {t("common.btn.delete")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
    </>
  );
};

export default InvoiceInfoList;
